import React from "react";
import Blueberry from "../../../blueberry";
import { Container, Section } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Message } from "../../sdk/Elements";
import { Field, Control, SelectBox, FieldBody } from "../../sdk/Form";
import { getDefaultUrl } from "../../utils/SEOFieldsHelmet";

function GenerateLinks({ token, wishlistId, user }) {
    const [email, setClientPDFEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [lastName, setLastName] = React.useState("");
    const [links, setLinks] = React.useState([]);
    const handleOnChangePDFEmail = (e) => {
        setClientPDFEmail(e.target.value);
    };

    const getLinksForPdf = () => {
        setIsLoading(true);

        Blueberry.getLinksForPdf({
            wishlistId,
        })
            .then((resp) => {
                setIsLoading(false);
                setLinks(resp);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const generateLink = () => {
        setIsLoading(true);

        Blueberry.generateLinkForPDF({
            wishlistId,
            user: {
                firstName,
                lastName,
                email,
            },
        })
            .then((resp) => {
                setIsLoading(false);
                getLinksForPdf();
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    React.useEffect(() => {
        getLinksForPdf();
    }, []);

    if (user.roles == null || user.roles.indexOf("salesrep") <= -1) return null;
    return (
        <Section>
            <Container>
                <Field isHorizontal>
                    <FieldBody isFlexEnd>
                        <Field>
                            <Control isExpanded>
                                <label>Primer Nombre de la Compradora</label>
                                <input
                                    value={firstName}
                                    name="firstName"
                                    type="text"
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    required={true}
                                    aria-invalid="false"
                                    placeholder="Primer Nombre del Cliente"
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Control isExpanded>
                                <label>Apellido de la Compradora</label>
                                <input
                                    value={lastName}
                                    type="text"
                                    name="lastName"
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    placeholder="Apellido del Cliente"
                                    required={true}
                                    aria-invalid="false"
                                />
                            </Control>
                        </Field>
                    </FieldBody>
                </Field>
                {/* <Field isHorizontal> */}
                {/* <FieldBody isFlexEnd> */}
                <Field>
                    <Control isExpanded>
                        <label>
                            Email de la Compradora
                            <br />
                            <br />* Si el correo no Existe se creara un usuario
                            nuevo. Por favor verificar su nivel de precio
                        </label>
                        <input
                            value={email}
                            name="email"
                            type="email"
                            onChange={handleOnChangePDFEmail}
                            placeholder="Correo del cliente que le vas a enviar el PDF
                            Dinamico"
                            required={true}
                            aria-invalid="false"
                        />
                    </Control>
                </Field>
                <Field isNarrow>
                    <Button onClick={generateLink} primary loading={isLoading}>
                        Generar Link
                    </Button>
                </Field>
                {/* </FieldBody> */}
                {/* </Field> */}
                {links.map((index) => (
                    <Message key={index._id}>
                        {index.email}:{" "}
                        <a
                            href={`${getDefaultUrl()}/plugins/pdf/index.html?token=${
                                index.token
                            }`}
                        >{`${getDefaultUrl()}/plugins/pdf/index.html?token=${
                            index.token
                        }`}</a>
                    </Message>
                ))}
            </Container>
        </Section>
    );
}

export default GenerateLinks;
