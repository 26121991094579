export default {
	hero: {
		title: "Products to Replace <br /> Noritex, S.A.",
		description: "You can select some products to replace.",
	},
	description: "Hi, ({{counter}}) items of your order won't manufacture, but you can select some alternative.",
	title: "Items to replace",
	totalAmount: "Total to be replaced: ",
	pending: "Balance to be replaced:",
	confirmBtn: "Confirm Replacements",
	confirmedBtn: "Your replacements have been sent to the seller. You will receive an email with the updated proforma.",
	detailsBtn: "See Detail",
	selectBtn: "Select replacement",
	noData: "There are not items to replace in your order",
	cxbLabel: "Package",
	seeMore: "See More",
	omittedBtn: "Omitted this product",
	totalLabel: "Total: ",
	selectOmmitted: "Omitted replacement",
	accept: "Accept",
	selectTitle: "Select replacement",
	selectDescrip: "Please, select an options.",
	selectDescrip1: "You can change it later if you want.",
	exit: "Close",
	detailTitle: "Product's Detail",
	modify: "Replace",
	unknowClient: "No Client",
	invoiceLabel: "Order: ",
	quantity: "Ordered Quantity",
	price: "Price",
	salesrep: "Sales Rep: ",
	noSelectBtn: "Discard selection",
	disabledLink: "Link has expired",
	canceledProd: "Canceled Product",
	selectOption: "Select an option",
	replacedProducts: "Replaced Products",
	replacedProduct: "Replaced Product",
	selectedProduct: "Selected Product",
	cart: "Cart: ",
	pendingreplacedProducts: "There are products pending to be replaced",
	totalReplaced: "Total Replaced:",
};
