import React from "react";
import Helmet from "react-helmet";
import ProductSectionTemplate from "../templates/ProductsSectionTemplate";
import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry";
import ListDetail from "./components/ListDetail";

class WishlistView extends ProductSectionTemplate {
    constructor(props) {
        super(props);

        this.state = {
            key: 0,

            isHeaderOpen: true,
            ...this.productDefaultState,
        };
    }

    addToCart = (productId, quantity, productObj) => {
        const self = this;
        self.setState({ isAddingToCart: true });

        if (analytics) {
            analytics.track("Wishlist Product Added to Cart", {
                product_id: productObj.slug,
                name:
                    productObj.title ||
                    productObj.title_eng ||
                    productObj.title_pt,
            });
            analytics.track("Product Added", {
                product_id: productObj.slug,
                name:
                    productObj.title ||
                    productObj.title_eng ||
                    productObj.title_pt,
                quantity: quantity,
                ...productObj,
            });
        }
        return new Promise((resolve, reject) => {
            Blueberry.productAddToCart({ productId, quantity })
                .then(() => {
                    self.setState({
                        isAddingToCart: false,
                        lastInCart: productId,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    componentDidMount() {
        const { token, lang } = this.props.match.params;
        const { location } = this.props;

        if (
            token != null &&
            this.props.location.pathname.indexOf("/accept/") > -1
        ) {
            Blueberry.acceptInvitation({
                public_token: token,
                accepted: true,
            })
                .then((resp) => {
                    this.props.history.push(`/${lang}/wishlist/${resp}`);
                })
                .catch((err) => {
                    if (err && err.reason === "Not authorized") {
                        this.props.history.push(
                            `/${lang}/account/login/?redirect_url=${location.pathname}`
                        );
                    }
                });
        }
    }

    wishlistGenerateExcel = (wishlistId, isPdf) => {
        const windowReference = window.open("", "_blank");
        if (analytics) {
            analytics.track("Wishlist Generated Excel");
        }
        Blueberry.generateProforma({ wishlistId }).then((resp) => {
            console.log(resp);
            windowReference.location = `${resp}${isPdf ? "&type=pdf" : ""}`;
            // window.open(`${resp}${sort ? "&sort=" + sort : ""}`, "_blank");
            setIsLoadingMenu(false);
            handleNotify({
                paragraph: _t("proformaGenerada"),
                info: true,
            });
        });
    };

    deleteWishlist = (wishlistId) => {
        return new Promise((resolve, reject) => {
            Blueberry.removeWishlist({ wishlistId })
                .then((resp) => {
                    this.props.history.goBack();
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };

    addWishlistToCart = (products) => {
        if (analytics) analytics.track("Wishtlist Products Added All To Cart");
        return Blueberry.productAddToCartMany({ productsToAdd: products });
    };

    emptyWishlist = (wishlistId) => {
        return Blueberry.upsertWishlist({
            wishlist: { _id: wishlistId, products: [] },
        });
    };

    inviteWishlist = (wishlistId, email) => {
        return Blueberry.inviteWishlist({
            wishlistId,
            email,
        });
    };

    renderBody() {
        const { token } = this.props.match.params;
        const { lang } = this.props.match.params;
        const { user, wishlists } = this.props.store;

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>Wishlists - Noritex</title>
                </Helmet>
                <ListDetail
                    user={user}
                    token={token}
                    setWindowTop={this.setWindowTop}
                    addToCart={this.addToCart}
                    deleteWishlist={this.deleteWishlist}
                    emptyWishlist={this.emptyWishlist}
                    _t={this._t}
                    toggleModalProduct={this.toggleModalProduct}
                    wishlists={wishlists}
                    addWishlistToCart={this.addWishlistToCart}
                    inviteWishlist={this.inviteWishlist}
                    viewItem={this.viewItem}
                    wishlistGenerateExcel={this.wishlistGenerateExcel}
                />
            </>
        );
    }
}

export default withTracker()(WishlistView);
