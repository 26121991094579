import React from "react";
import { Section, Heading } from "../../sdk/Layout";
import ProductGrid from "../../components/ProductGrid2";
import Blueberry from "../../../blueberry";
import { useInView } from "react-intersection-observer";

function SimilarProducts({
    title,
    viewItem,
    addToCart,
    scenario,
    limit,
    slug,
    toggleModalProduct,
}) {
    const [isLoadingProducts, setIsLoadingProducts] = React.useState(false);
    const [products, setProducts] = React.useState(null);
    const refSlug = React.useRef();
    const refTimeout = React.useRef();
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        // triggerOnce: true,
    });
    const getProducts = () => {
        setIsLoadingProducts(true);
        setProducts(null);
        Blueberry.itemToItem({
            slug,
            limit,
            // logic: "ecommerce:similar-products",
            scenario: scenario ? scenario : "similaritems",
            resetSimilar: true,
        })
            .then((resp) => {
                setProducts({ products: [...resp] });
                setIsLoadingProducts(false);
            })
            .catch((err) => {
                setIsLoadingProducts(false);
                // this.setState({isLoadingItems: false, errorLoadingItems: true})
            });
    };

    React.useEffect(() => {
        if (refSlug.current !== slug) {
            refSlug.current = slug;
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
                refTimeout.current = null;
            }

            if (inView) {
                getProducts();
            } else {
                refTimeout.current = setTimeout(() => {
                    getProducts();
                }, 1000);
            }
        }
        return () => {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
        };
    }, [slug]);

    return (
        <Section noPadding>
            <Heading isCentered ref={ref}>
                <h3>{title}</h3>
            </Heading>
            <ProductGrid
                products={products}
                grid="x5"
                isRetrivingProducts={isLoadingProducts}
                padding="0"
                lazyLoadAll={true}
                viewItem={viewItem}
                addToCart={addToCart}
                onQuickView={toggleModalProduct}
            />
        </Section>
    );
}

export default React.memo(SimilarProducts);
