/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";
import Icons from "./Icons";
import { numberWithCommas } from "../../../utils";
import { ProductCard, Spacer } from "../style";
import { Button, Buttons } from "../../../sdk/Button";
import { Preloader } from "../../../sdk/Elements";
import IconButton from "./IconButton";

function ProductCardComponent({
	data,
	loading,
	titleField,
	lj,
	isReplaced,
	setIsEdit,
	selectMobile,
	isMobile,
	productDetail,
}) {
	const noImage = "https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg";
	const img = loading
		? noImage
		: (data.imagesUrl || [])[0]?.thumb || noImage;
	const isOmitted = data?.sku === "omitted";
	const getContent = (value) => (loading ? <Preloader /> : value);
	const product = React.useMemo(() => (data?.products || []).filter(
		(item) => item.slug === (data?.slug || data?.sku),
	)[0], [data?.sku]);

	return (
		<ProductCard>
			{isOmitted ? (
				<div className="content omitted">
					<h3>{lj?.selectOmmitted}</h3>
					<Spacer />
					<Buttons>
						<Button
							white
							small
							className="hide-on-breakpoint link"
							onMouseUp={() => setIsEdit(true)}
						>
							<Icons icon="find-replace" />
							<span>{lj?.modify}</span>
						</Button>
					</Buttons>
				</div>
			) : (
				<>
					<figure className="image">
						<img src={img} alt={data?.[titleField]} />
					</figure>
					<div className="content">
						<div className="ref">
							{getContent(`REF. ${product?.sku || data?.sku}`)}
						</div>
						<h3 className="title">
							{getContent(product?.[titleField])}
						</h3>
						<div className="price">
							{getContent(
								`$${numberWithCommas(data?.price)} ${lj.quantity}: ${
									data?.quantity || 1
								} ${lj.cxbLabel} ${
									data?.multiple || 1
								}`,
							)}
						</div>
						<Spacer />
						<Buttons>
							<Button
								white
								small
								className="hide-on-breakpoint alert"
								onMouseUp={() => productDetail(product)}
							>
								<Icons icon="info" />
								<span>{(lj || {}).detailsBtn}</span>
							</Button>
							{isReplaced && (
								<Button
									white
									small
									className="hide-on-breakpoint link"
									onMouseUp={() => setIsEdit(true)}
								>
									<Icons icon="find-replace" />
									<span>{(lj || {}).modify}</span>
								</Button>
							)}
						</Buttons>
					</div>
					{/* Este boton abre la modal de detalle del producto. Solo visible en mobile */}
					<div className="options">
						<IconButton
							icon="info"
							color="alert"
							onMouseUp={() => productDetail(product)}
						/>
						{isReplaced && (
							<IconButton
								icon="find-replace"
								onMouseUp={selectMobile}
							/>
						)}
					</div>
				</>
			)}
			{isReplaced && (
				<div
					className={`options-replace ${
						isOmitted && "omitted-replace"
					}`}
				>
					{isMobile && (
						<IconButton
							icon="find-replace"
							onMouseUp={selectMobile}
						/>
					)}
				</div>
			)}
		</ProductCard>
	);
}

export default ProductCardComponent;
