import React from "react";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import withTracker from "../../blueberry/connectPromise";
import { Container } from "../sdk/Layout";
import Listener from "../components/Listener";
// import Product from "../components/Product2";
import ProductsScrollable from "../components/ProductsScrollable";

import Icons from "./compontents/Icons";
import { asyncForEach } from "../utils";
import {
    Header,
    SectionExtended,
    Loader,
    TitleExtended,
    Scrollable,
    Block2,
} from "./style";
import Blueberry from "../../blueberry";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Personalized extends ProductsSectionTemplate {
    state = {
        menus: [],
        recomendations: [],
        isLoading: true,
        tagsAnalysis: [],
        page: 1,
    };

    componentDidMount() {
        if (typeof window === "undefined") return;

        // window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ isLoading: true });
            this.initialStatsDone = false;
        }
    }

    componentWillUnmount() {
        if (typeof window === "undefined") return;

        // window.removeEventListener("scroll", this.handleScroll);
    }

    checkLocalStorageForSavedData = () => {
        const { tag, type } = this.props.match.params;

        if (
            this.props.store.personalizedExperience[type][tag ? tag : " "] &&
            this.props.store.personalizedExperience[type][tag ? tag : " "]
                .length > 1
        ) {
            this.initialStatsDone = true;
            this.setState({ isLoading: false });
        }
    };

    // handleScroll = (event) => {
    //     if (this.isRequesting) return;
    //     if (
    //         window.innerHeight + window.scrollY >=
    //         document.body.offsetHeight - 1000
    //     ) {
    //         this.getStats(this.state.page + 1);
    //         this.setState((prev) => ({ page: prev.page + 1 }));
    //     }
    // };

    // getStats = () => {
    //     const { type, tag } = this.props.match.params;

    //     this.checkLocalStorageForSavedData();

    //     if (this.initialStatsDone) return;

    //     this.isRequesting = true;
    //     this.setState({
    //         isLoading: true,
    //         analyzandoPedido: false,
    //         gettingRecomendations: true,
    //     });
    //     Blueberry.getOrderStats({ type: type ? type : "hogar" })
    //         .then((resp) => {
    //             if (resp && !this.initialStatsDone) {
    //                 this.initialStatsDone = true;

    //                 asyncForEach(resp, async (element, index) => {
    //                     const elementClone = { ...element };
    //                     if (this.recomendationsCount < 10) {
    //                         await this.getRecomendationsFromTag(elementClone);
    //                         elementClone.hasRequest = true;
    //                         resp[index] = elementClone;
    //                     }
    //                     if (this.recomendationsCount === 3) {
    //                         this.setState({
    //                             isLoading: false,
    //                         });
    //                     }
    //                 })
    //                     .then(() => {
    //                         this.isRequesting = false;

    //                         Blueberry.addToPersonalizedTags({
    //                             fields: resp,
    //                             type,
    //                             tag,
    //                         });
    //                         this.setState({
    //                             isLoading: false,
    //                         });
    //                     })
    //                     .catch(() => {
    //                         this.isRequesting = false;
    //                         this.setState({
    //                             isLoading: false,
    //                         });
    //                     });
    //             }
    //         })
    //         .catch(() => {
    //             this.isRequesting = false;
    //             this.setState({
    //                 isLoading: false,
    //             });
    //         });
    // };

    initialDataRequest = () => {
        this.checkLocalStorageForSavedData();
        if (this.initialStatsDone) return;
        this.getStats(1);
    };

    getStats = (page) => {
        const { type, tag, lang } = this.props.match.params;
        console.log(page);
        this.isRequesting = true;
        this.setState({
            isLoading: true,
            analyzandoPedido: false,
            gettingRecomendations: true,
        });
        Blueberry.homePage({
            type: type ? type : "hogar",
            tag: tag ? tag : " ",
            filter: ` ${
                tag === "inventario" ? ` "INVENTARIO" == 'taginventario' ` : ""
            } ${tag && tag.indexOf("feria_") > -1 ? ` "${tag}" == 'feria'` : ""}
                                `,
            limit: 18,
            page: page ? page : 1,
            limitPerRow: 10,
            rotationRate: 0.5,
            includeBestsellers: true,
            includePersonal: true,
            recentlyViewed: true,
        })
            .then((resp) => {
                this.isRequesting = false;
                this.setState({
                    isLoading: false,
                    analyzandoPedido: false,
                    gettingRecomendations: false,
                });
            })
            .catch(() => {
                this.isRequesting = false;
                this.setState({
                    isLoading: false,
                    gettingRecomendations: false,
                });
            });
    };

    renderBody() {
        const { user, personalizedExperience } = this.props.store;
        const { isLoading, analyzandoPedido, gettingRecomendations } =
            this.state;
        const { lang, type, tag } = this.props.match.params;
        const langJson = getLang(lang);

        if (isLoading) {
            return (
                <Loader length={0}>
                    <div>
                        <ul>
                            <li>
                                <span className="icon">
                                    <span>
                                        <Icons icon="check"></Icons>
                                    </span>
                                </span>
                                {langJson["loading1"]}
                            </li>
                            <li>
                                <span className="icon">
                                    {analyzandoPedido ? (
                                        <span className="spin">
                                            <Icons icon="cog"></Icons>
                                        </span>
                                    ) : (
                                        <span>
                                            <Icons icon="check"></Icons>
                                        </span>
                                    )}
                                </span>

                                {langJson["loading2"]}
                            </li>
                            <li>
                                <span className="icon">
                                    {gettingRecomendations ? (
                                        <span className="spin">
                                            <Icons icon="cog"></Icons>
                                        </span>
                                    ) : (
                                        <span>
                                            <Icons icon="check"></Icons>
                                        </span>
                                    )}
                                </span>

                                {langJson["loading3"]}
                            </li>
                        </ul>
                    </div>
                    <Listener user={user} action={this.initialDataRequest} />;
                </Loader>
            );
        }

        return (
            <>
                <Helmet>
                    <title>{langJson.title}</title>
                    <body className="bg-dark"></body>
                </Helmet>
                <Header>
                    <img
                        src="https://www.noritex.com/T52dj6n8KziwTMAwmESMERALDA_LUXE.jpg"
                        alt=""
                    />
                    <div>
                        <h3>{this._t("basedOnYourHistory")}</h3>
                        <figure>
                            {/* <img
                                src="https://www.noritex.com/iydyBo8N5xHz84YKg/599-4215110001.jpg"
                                alt=""
                            /> */}
                        </figure>
                    </div>
                </Header>
                <SectionExtended>
                    <Container isFluid>
                        {personalizedExperience[type ? type : "hogar"][
                            tag ? tag : " "
                        ]
                            ? personalizedExperience[type ? type : "hogar"][
                                  tag ? tag : " "
                              ].map((index, i) => {
                                  return (
                                      <ProductsScrollable
                                          index={index}
                                          key={`recomends-${i}`}
                                          light
                                          viewItem={this.viewItem}
                                          addToCart={this.addToCart}
                                          onQuickView={this.toggleModalProduct}
                                      />
                                  );
                              })
                            : null}
                    </Container>
                </SectionExtended>
            </>
        );
    }
}

export default withTracker()(Personalized);

// export default withSSR(Page);
