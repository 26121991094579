/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
	ProductRowList,
	ProductRow,
	ButtonExtended,
	FooterSection,
	Spacer,
} from "../style";
import { Buttons, Button } from "../../../sdk/Button";
import { Container } from "../../../sdk/Layout";
import ProductItem from "./ProductItem";

function ProductRowListComponent({
	loading,
	data,
	lj,
	isMobile,
	className,
	productDetail,
}) {
	const [showSeeMore, setShowSeeMore] = useState(true);
	const [selected, setSelected] = useState();
	const [items, setItems] = useState([]);
	const { sku, titleField } = data || {};
	const noImage =	"https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg";
	const img = loading
		? noImage
		: ((data.imagesUrl || [])[0] || {}).thumb || noImage;

	React.useEffect(() => {
		if ((data?.recomms || []).length) {
			setItems(
				data.recomms.map((item) => {
					for (let i = 0; i < (data.products || []).length; (i += 1)) {
						const product = data.products[i];
						if (product.slug === item) {
							if (data?.replaced?.sku === product.sku) {
								return { ...product, ...data.replaced };
							}
							const replacedItemT = (data?.allItems || [])
								.filter((item1) => item1.replaced?.sku === product.sku)[0];
							if (replacedItemT) {
								return { ...product, selected: true };
							}
							return product;
						}
					}
					return { _id: item, sku: item };
				}).filter((item) => !item.selected),
			);
		}
		if (data?.replaced?.sku) {
			setSelected(data.replaced);
		}
	}, [data?.allItems]);

	return (
		<ProductRowList className={className} isMobile={isMobile}>
			{loading ? (
				<>
					<ProductItem lj={lj} loading={loading} />
					<ProductItem lj={lj} loading={loading} />
				</>
			) : (
				<>
					{isMobile && (
						<>
							<div className="heading">
								<h2>{lj?.selectTitle}</h2>
								<h3>{data?.[titleField]}</h3>
								<h4>{`REF. ${data?.sku}`}</h4>
							</div>
							<div className="feature-image">
								<img src={img} alt={data?.[titleField]} />
							</div>
							<div className="description">
								<p>{lj?.selectDescrip}</p>
								<p>{lj?.selectDescrip1}</p>
							</div>
						</>
					)}
					{items.map((item, key) => {
						if (
							!showSeeMore
							|| [0, 1, 2].includes(key)
						) {
							return (
								<ProductItem
									key={item._id}
									lj={lj}
									sku={sku}
									item={item}
									data={data}
									selected={selected}
									setSelected={setSelected}
									productDetail={productDetail}
								/>
							);
						}
						// return <div key={item._id} />;
					})}
					{!!(showSeeMore && items.length > 2 && data?.order?.brandId == "tyJbdXkZCercBFmnK-santini") && (
						<ProductRow>
							<ButtonExtended
								isFullWidth
								onMouseUp={() => setShowSeeMore(!showSeeMore)}
							>
								{lj.seeMore}
							</ButtonExtended>
						</ProductRow>
					)}
					<ProductItem
						lj={lj}
						sku={sku}
						item={{ sku: "omitted" }}
						data={data}
						selected={selected}
						setSelected={setSelected}
					/>
					{/* selected && (
						<ProductItem
							lj={lj}
							sku={sku}
							item={{ sku: "noSelect" }}
							data={data}
							selected={selected}
							setSelected={setSelected}
						/>
					) */}
					<Spacer />
					{isMobile && (
						<FooterSection>
							<Container className="container">
								<Buttons isRight>
									<Button
										primary
										responsive
										disabled={!selected}
										white={!selected}
										onMouseUp={() => data.setModalMobile(false)}
									>
										{lj.accept}
									</Button>
								</Buttons>
							</Container>
						</FooterSection>
					)}
				</>
			)}
		</ProductRowList>
	);
}

export default ProductRowListComponent;
