import styled from "styled-components";

import { Container, Section, Heading, Title } from "../sdk/Layout";
import { Buttons, Button } from "../sdk/Button";
import { Item, TagExtented } from "../components/Product2";
import Icons from "../components/Icons2";

export const Header = styled.div`
    height: 600px;
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 75px;
    margin-bottom: -150px;
    > div {
        width: 100%;
        height: 100%;
        text-align: left;
        z-index: 3;
        padding: 40px 20px 50px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            color: white;
            font-size: 35px;
            width: 50%;
        }

        figure {
            position: relative;
            width: 45%;
            height: 100%;
            text-align: right;
            img,
            iframe,
            video,
            span {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 -200px 100px -50px #141414;
        z-index: 2;
    }
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0.4;
    }

    @media (max-width: 1024px) {
        height: 500px;
        > div {
            h3 {
                font-size: 24px;
            }
            figure {
                width: 30%;
            }
        }
    }
    @media (max-width: 768px) {
        height: 350px;
        > div {
            justify-content: center;
            text-align: center;
            figure {
                display: none;
            }
            h3 {
                width: 100%;
                max-width: 400px;
                font-size: 18px;
            }
        }
    }
`;

export const SectionExtended = styled(Section)`
    position: relative;
    z-index: 1;
`;

export const TitleExtended = styled(Title)`
    color: lightgray;
    text-transform: capitalize;
    span {
        font-weight: normal;
        font-size: 17px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        span {
            font-size: 14px;
            padding-top: 5px;
            display: block;
        }
    }
    @media (max-width: 480px) {
        font-size: 3.333vw;
        span {
            font-size: 2.917vw;
        }
    }
`;

export const Scrollable = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    > div {
        white-space: nowrap;
        padding-left: 0;
        padding-right: 100px;
    }
    @media (max-width: 768px) {
        > div {
            padding-right: 50px;
        }
    }
    ${Item} {
        border-radius: 10px;
        opacity: 0.9;
        transition: opacity 200ms linear;
        overflow: hidden;
        &:hover {
            opacity: 1;
        }
        @media (max-width: 768px) {
            width: 24% !important;
            display: inline-block;
            padding: 10px !important;
            margin-bottom: 10px;
            border: solid 1px #e3e3e3;
            ${TagExtented} {
                height: 25px;
                line-height: 25px;
                padding: 0 10px;
                bottom: 120px !important;
                top: inherit !important;
                font-size: 12px;
            }
            > div {
                padding-top: 20px !important;
                padding-left: 0;
                width: 100%;
            }

            ${Buttons} {
                position: absolute;
                top: 0;
                margin-top: -20px;
                left: 0;
                width: 100%;
                padding: 0px 10px;
                justify-content: center !important;
                right: inherit;
            }
            .info {
                display: none;
            }
        }
        @media (max-width: 620px) {
            width: 49% !important;
        }
    }
`;

export const Block2 = styled.div`
    &:not(:last-child) {
        margin-bottom: 30px;
    }
`;

export const Loader = styled.div`
    background-color: #141414;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
            180deg,
            rgba(20, 20, 20, 1) 89%,
            rgba(20, 20, 20, 0.2595238779105392) 100%
        );
    }
    &:after {
        top: initial;
        bottom: 0;
        height: calc(50% - 35px * 5);
        background: linear-gradient(
            0deg,
            rgba(20, 20, 20, 1) 89%,
            rgba(20, 20, 20, 0.2595238779105392) 100%
        );
    }
    > div {
        transition: all 200ms ease-out;
        position: absolute;
        left: calc(50% - 200px);
        ${(props) =>
            `top: calc(50% - ${
                props.length > 1 ? props.length - 1 : "0"
            } * 35px);`}
        @media (max-width: 480px) {
            left: 20px;
        }
    }
    ul {
        li {
            display: flex;
            align-items: center;
            color: white;
            opacity: 0.5;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            .icon {
                margin-right: 7px;
                width: 20px;
                height: 20px;
            }
            .spin {
                display: inline-block;
                width: 20px;
                height: 20px;
                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                animation-name: spin;
                animation-duration: 3000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            svg {
                width: 20px;
            }
        }
    }
`;
