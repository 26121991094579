import styled, { css } from "styled-components";

import { Column } from "../../sdk/Layout";
import { Item } from "../Product2";
import { ItemsContainer } from "../ProductGrid2";

export const LookbookItems = styled(Column)`
    margin-left: -1px;
    padding: 0;
    ${Item} {
        width: 20% !important;
        display: block !important;
        margin: 0;
        border-top: 0;
        border-left: 0;
        opacity: 0.85;
        figure {
            width: 100%;

            div {
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        p {
            padding-bottom: 0;
        }
        &:hover {
            opacity: 1;
            border-color: ${(props) => props.theme.borderColor} !important;
        }
        @media (max-width: 980px) {
            width: 33.3333% !important;
            padding: 10px;
        }
    }
    ${ItemsContainer} {
        padding: 0;
        margin: 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        &:before {
            content: "";
            width: 1px;
            background-color: ${(props) => props.theme.white};
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 1;
        }
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: ${(props) => props.theme.white};
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }
`;

export const LookbookImagen = styled(Column)`
    padding: 0;
    padding-right: 20px;
    width: 30%;
    figure {
        img {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
        figure img {
            max-width: 300px;
            margin: auto;
            display: block;
        }
    }
`;
