import React, { useState } from "react";
import styled from "styled-components";
import {
    Container,
    Section,
    Title,
    Subtitle,
    Level,
    LevelLeft,
    LevelRight,
    LevelItem,
    BlankState,
} from "../../sdk/Layout";
import { Button, Icon } from "../../sdk/Button";
import { Dropdown, Message } from "../../sdk/Elements";
import { Field, Control, SelectBox, FieldBody } from "../../sdk/Form";
// import Products from "./Products";
import ProductGrid from "../../components/ProductGrid2";
import IconLibrary from "./Icon";
import Modal from "./Modal";
import GenerateLinks from "./GenerateLinks";
import IconLibrary2 from "../../components/Icons2";
import useConfirm from "../../hooks/useConfirm";
import useNotification from "../../hooks/useNotification";
import InternalLink from "../../components/InternalLink";

import Blueberry from "../../../blueberry";
import useClickOutside from "../../hooks/useClickOutside";

const Header = styled.div`
    padding-bottom: 30px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    border-bottom: solid 1px ${(props) => props.theme.borderColor};
    ${Title} {
        margin-bottom: 0;
        @media (max-width: 680px) {
            font-size: 18px;
        }
    }
    ${Button} {
        margin-bottom: 0;
    }

    .divisor {
        height: 40px;
        width: 1px;
        background-color: #ecf0f4;
        margin: 0 15px;
    }
`;

const SubHeader = styled(Level)`
    padding-bottom: 10px;
    ${Subtitle} {
        margin-bottom: 0;
    }
    select {
        max-width: 170px;
    }
`;

const Menu = styled(Dropdown)`
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
        }
        a {
            cursor: pointer;
        }
    }
    > div {
        > div {
            border: solid 1px #e3e3e3;
        }
    }
`;

const ItemContainer = styled.div`
    margin-right: -0.5%;
    margin-left: -0.5%;
`;

function DetalleLista({
    token,
    addToCart,
    addWishlistToCart,
    emptyWishlist,
    deleteWishlist,
    inviteWishlist,
    wishlistGenerateExcel,
    viewItem,
    toggleModalProduct,
    setWindowTop,
    user,
    _t,
    wishlists,
}) {
    const [isModalActive, setModalActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState(null);
    const [isOptionsVisible, setIsOptionsVisible] = React.useState(false);
    const [isSharing, setIsSharing] = React.useState(false);
    const [hasError, setError] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const menuRef = React.useRef();

    const [limit, setLimit] = React.useState(35);
    const [page, setPage] = React.useState(1);

    useClickOutside(menuRef, () => setIsOptionsVisible(false));

    const [handleConfirm, ConfirmModal] = useConfirm({
        title: _t("confirmDeleteList"),
        paragraph: "",
        btnConfirm: _t("delete"),
        btnCancel: _t("cancel"),
    });

    const [handleNotifyDelete, NotifyDeleteWishlist] = useNotification({
        timeout: 2000,
    });

    const [order, setOrder] = React.useState(() => {
        const defaultOrder = window.localStorage.getItem("order");

        if (defaultOrder != null && defaultOrder !== "") return defaultOrder;
        return "slug-descending";
    });

    const [handleConfirmEmpty, ConfirmModalEmpty] = useConfirm({
        title: _t("confirmEmptyList"),
        paragraph: "",
        btnConfirm: _t("empty"),
        btnCancel: _t("cancel"),
    });

    const handleOnChange = (e) => {
        setEmail(e.target.value);
    };
    const handleSetPage = (page) => {
        setWindowTop();
        setPage(page);
    };

    const count = products && products.count ? products.count : 0;

    const toggleModal = () => {
        setModalActive((prev) => !prev);
        setIsOptionsVisible(false);
    };

    const toggleOptions = () => {
        setIsOptionsVisible((prevState) => !prevState);
    };

    const getWishlist = () => {
        setProducts(null);
        setIsLoading(true);
        Blueberry.detailsWishlist({
            wishlistId: token,
            order,
            page,
            slug: "",
            limit,
        })
            .then((resp) => {
                setIsLoading(false);
                setProducts(resp);
            })
            .catch(() => {
                setIsLoading(false);
                setError(true);
            });
    };

    const handleDeleteWishlist = () => {
        setIsOptionsVisible(false);
        handleConfirm({
            callback: function (confirmed) {
                if (confirmed) {
                    deleteWishlist(token)
                        .then(() => {
                            handleNotifyDelete({
                                paragraph: _t("wishlistDeleted"),
                                info: true,
                            });
                        })
                        .catch(() => {
                            handleNotifyDelete({
                                paragraph: _t("noPermisionsLIst"),
                                error: true,
                            });
                        });
                }
            },
        });
    };

    const handleEmptyWishlist = () => {
        handleConfirmEmpty({
            callback: function (confirmed) {
                if (confirmed) {
                    emptyWishlist(token)
                        .then(() => {
                            getWishlist();
                            handleNotifyDelete({
                                paragraph: _t("wishlistEmptied"),
                                info: true,
                            });
                        })
                        .catch(() => {
                            handleNotifyDelete({
                                paragraph: _t("noPermisionsLIst"),
                                error: true,
                            });
                        });
                }
            },
        });

        setIsOptionsVisible(false);
    };

    const handleShare = () => {
        setIsSharing(true);
        inviteWishlist(token, email)
            .then((resp) => {
                setModalActive(false);
                setIsSharing(false);
                setEmail("");
                handleNotifyDelete({
                    paragraph: _t("invitationSend"),
                    info: true,
                });
            })
            .catch(() => {
                setIsSharing(false);
                handleNotifyDelete({
                    paragraph: _t("error"),
                    error: true,
                });
            });
    };

    const handleAddWishlistToCart = () => {
        if (wishlists && wishlists.length > 0) {
            const list = wishlists.filter((index) => index._id === token);
            if (list && list.length > 0) {
                addWishlistToCart(
                    list[0].products.map((index) => {
                        return {
                            productId: index,
                            quantity: null,
                        };
                    })
                )
                    .then(() => {
                        handleNotifyDelete({
                            paragraph: _t("addedAllToCart"),
                            info: true,
                        });
                    })
                    .catch((err) => {
                        handleNotifyDelete({
                            paragraph: _t("noPermisionsLIst"),
                            error: true,
                        });
                    });
                setIsOptionsVisible(false);
            }
        }
    };

    const removeProduct = (productId) => {
        return new Promise((resolve) => {
            Blueberry.removeProductWishlist({
                productId,
                wishlistId: token,
            }).then((resp) => {
                getWishlist();
                resolve();
            });
        });
    };

    const handleProforma = () => {
        wishlistGenerateExcel(token);
        setIsOptionsVisible(false);
    };

    const handlePdfGenerate = () => {
        wishlistGenerateExcel(token, true);
        setIsOptionsVisible(false);
    };

    const onOrderChange = (e) => {
        setOrder(e.target.value);
    };

    React.useEffect(() => {
        getWishlist();
    }, [token, order, page, limit]);

    return (
        <>
            <ConfirmModal />
            <ConfirmModalEmpty />
            <NotifyDeleteWishlist />
            <Section>
                <Container>
                    <Header>
                        <Level isMobile>
                            <LevelLeft>
                                <LevelItem>
                                    <InternalLink
                                        className="hide-on-standalone"
                                        to="/wishlist"
                                    >
                                        <Button>
                                            <IconLibrary icon="back" />
                                        </Button>
                                    </InternalLink>
                                    <span className="divisor hide-on-standalone"></span>
                                    <Title size="5" as="h3">
                                        {products
                                            ? products.title
                                            : "Loading..."}
                                    </Title>
                                </LevelItem>
                            </LevelLeft>
                            <LevelRight>
                                <LevelItem>
                                    <Menu
                                        isActive={isOptionsVisible}
                                        ref={menuRef}
                                        isRight
                                    >
                                        <Button onClick={toggleOptions}>
                                            <IconLibrary icon="elipsis-vertical" />
                                        </Button>
                                        <div>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <a
                                                            onClick={
                                                                handleProforma
                                                            }
                                                        >
                                                            <Icon>
                                                                <IconLibrary2 icon="excel-blank" />
                                                            </Icon>
                                                            <span>
                                                                {_t(
                                                                    "generarProforma"
                                                                )}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            onClick={
                                                                handlePdfGenerate
                                                            }
                                                        >
                                                            <Icon>
                                                                <IconLibrary2 icon="pdf-blank" />
                                                            </Icon>
                                                            <span>
                                                                {_t(
                                                                    "generatePdf"
                                                                )}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            onClick={
                                                                handleAddWishlistToCart
                                                            }
                                                        >
                                                            <Icon>
                                                                <IconLibrary icon="cart-arrow" />
                                                            </Icon>
                                                            <span>
                                                                {_t("addAll")}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            onClick={() =>
                                                                toggleModal()
                                                            }
                                                        >
                                                            <Icon>
                                                                <IconLibrary icon="share" />
                                                            </Icon>
                                                            <span>
                                                                {" "}
                                                                {_t("share")}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a >
                                                            <Icon>
                                                                <IconLibrary icon="copy" />
                                                            </Icon>
                                                            <span>
                                                                Duplicar
                                                            </span>
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <a
                                                            onClick={
                                                                handleEmptyWishlist
                                                            }
                                                        >
                                                            <Icon>
                                                                <IconLibrary2 icon="eraser" />
                                                            </Icon>
                                                            <span>
                                                                {_t("empty")}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            onClick={
                                                                handleDeleteWishlist
                                                            }
                                                        >
                                                            <Icon>
                                                                <IconLibrary icon="trash" />
                                                            </Icon>
                                                            <span>
                                                                {_t("delete")}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Menu>
                                </LevelItem>
                            </LevelRight>
                        </Level>
                    </Header>

                    <SubHeader isMobile>
                        <LevelRight>
                            <LevelItem>
                                <Field hasAddons>
                                    <Control>
                                        <Button static>
                                            <IconLibrary2 icon="sort" />
                                        </Button>
                                    </Control>
                                    <Control>
                                        <SelectBox>
                                            <select
                                                onChange={onOrderChange}
                                                value={order}
                                            >
                                                <option value="slug-ascending">
                                                    {_t("fabricaLowHigh")}
                                                </option>
                                                <option value="slug-descending">
                                                    {_t("fabricaHighLow")}
                                                </option>
                                                <option value="price-ascending">
                                                    {_t("priceLowHigh")}
                                                </option>
                                                <option value="price-descending">
                                                    {_t("priceHighLow")}
                                                </option>
                                                <option value="title-ascending">
                                                    A-Z
                                                </option>
                                                <option value="title-descending">
                                                    Z-A
                                                </option>
                                                <option value="created-ascending">
                                                    {_t("oldestNewest")}
                                                </option>
                                                <option value="created-descending">
                                                    {_t("newestOldest")}
                                                </option>
                                                <option value="minventa-ascending">
                                                    {_t("minVentaAsec")}
                                                </option>
                                                <option value="minventa-descending">
                                                    {_t("minVentaDesc")}
                                                </option>
                                            </select>
                                        </SelectBox>
                                    </Control>
                                </Field>
                            </LevelItem>
                        </LevelRight>
                        <LevelLeft>
                            <Subtitle as="h4" size="6">
                                {count} items
                            </Subtitle>
                        </LevelLeft>
                    </SubHeader>

                    {/* <Products></Products> */}

                    <ItemContainer>
                        <ProductGrid
                            products={products}
                            grid="x5"
                            padding="0 7px"
                            onQuickView={toggleModalProduct}
                            limit={limit}
                            currentPage={page}
                            setPage={handleSetPage}
                            addToCart={addToCart}
                            viewItem={viewItem}
                            removeProduct={removeProduct}
                            isRetrivingProducts={isLoading}
                        />
                    </ItemContainer>
                    {count === 0 && !isLoading && !hasError ? (
                        <BlankState>
                            <div>
                                <IconLibrary2 icon="plus-square" />
                                <h3>{_t("listEmpty")}</h3>
                                <p>{_t("listEmptyHelp")}</p>
                            </div>
                        </BlankState>
                    ) : null}
                    {count === 0 && hasError ? (
                        <BlankState>
                            <div>
                                <h3>Error</h3>
                                <p>{_t("error")}</p>
                            </div>
                        </BlankState>
                    ) : null}
                </Container>
            </Section>
            <GenerateLinks wishlistId={token} user={user} />
            <Modal
                isActive={isModalActive}
                toggleModal={toggleModal}
                email={email}
                onChange={handleOnChange}
                onSubmit={handleShare}
                isLoading={isSharing}
            />
        </>
    );
}

export default DetalleLista;
