// @ts-nocheck
/* eslint-disable react/prop-types */
import React from "react";
import ProductRowList from "./components/ProductRowList";
import ProductCard from "./components/ProductCard";
import ProductPicker from "./components/ProductPicker";
import Icons from "./components/Icons";
import ProductDetails from "./components/ProductDetails";
import { ColumnLeft, ColumnRight, ProductGroupRow } from "./style";

function ProductGroup({ loading, lj, data }) {
	const firstTime = React.useRef(true);
	const [isEdit, setIsEdit] = React.useState(false);
	const replacedItem = React.useMemo(() => {
		if (data?.sku && (firstTime.current || data?.isMobile)) {
			if (data?.replaced?.sku) {
				const resolve = (data || {}).replaced;
				const prodFound = (data.products || []).filter(
					(item) => item.sku === ((data || {}).replaced || {}).sku,
				)[0];
				if (prodFound) return { ...prodFound, ...resolve };
				return resolve;
			}
			firstTime.current = false;
		}
		return false;
	}, [data?.replaced]);

	const productDetail = (item) => data.setModalDetail(
		<ProductDetails
			lj={lj}
			item={item}
			titleField={data?.titleField}
			close={data.setModalDetail}
		/>,
	);

	const selectMobile = () => {
		data.setModalMobile(
			<ProductRowList
				lj={lj}
				data={data}
				loading={loading}
				productDetail={productDetail}
				isMobile
			/>,
		);
	};

	return (
		<ProductGroupRow>
			<ColumnLeft>
				<div className={`indicator ${!!(data?.replaced) && "success"}`}>
					<Icons icon="arrow-right" />
				</div>
				<ProductCard
					lj={lj}
					data={data}
					titleField={data?.titleField}
					loading={loading}
					productDetail={productDetail}
				/>
			</ColumnLeft>
			<ColumnRight>
				{replacedItem && !isEdit ? (
					<ProductCard
						lj={lj}
						data={{ ...replacedItem, products: data?.products }}
						titleField={data?.titleField}
						setIsEdit={setIsEdit}
						selectMobile={selectMobile}
						productDetail={productDetail}
						isMobile={(data || {}).isMobile}
						loading={loading}
						isReplaced
					/>
				) : (
					<>
						{!isEdit && (
							<ProductPicker
								lj={lj}
								className="show-on-breakpoint"
								loading={loading}
								onMouseUp={selectMobile}
							/>
						)}
						<ProductRowList
							lj={lj}
							data={data}
							productDetail={productDetail}
							className="hide-on-breakpoint"
							loading={loading}
						/>
					</>
				)}
			</ColumnRight>
		</ProductGroupRow>
	);
}

export default ProductGroup;
