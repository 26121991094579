import styled from "styled-components";
import { Container } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Modal } from "../../sdk/Elements";

const breakpoint = "980px";

export const Spacer = styled.div`
  height: ${(props) => props.height ?? "10"}px;
`;

export const NoDataCont = styled.div`
  margin-top: 50px;
  text-align: center;
  h3 {
    font-size: 20px;
    padding: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 35px;
  .orderDetailsCont {
    width: 100%;
    max-width: 370px;
    @media (max-width: ${breakpoint}) {
      margin-bottom: 20px;
      max-width: 100%;
    }
    .orderDetails{
      text-align: right;
      font-size: 14px;
      margin-right: 4px;
      @media (max-width: ${breakpoint}) {
        font-size: 12px;
      }
    }
  }
  .heading {
    max-width: 480px;
    h1 {
      font-size: 22px;
      font-weight: normal;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
    }
  }
  @media (max-width: ${breakpoint}) {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 29px;
    .heading {
      text-align: center;
      p {
        font-size: 14px;
      }
    }
  }
`;

export const Layout = styled.div`
  /* height: calc(100vh - 81px); */
  padding: 50px 10px;
  @media (max-width: ${breakpoint}) {
    padding: 10px;
  }
`;

export const Progress = styled.div`
  border: solid 1px ${(props) => props.theme.borderColor};
  border-radius: ${(props) => props.theme.radio};
  font-size: 12px;
  padding: 10px;
  width: 100%;
  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      color: ${(props) => props.theme.gray};
    }
  }
  .progressbar {
    height: 7px;
    width: 100%;
    background-color: ${(props) => props.theme.graySoft};
    border-radius: ${(props) => props.theme.radio};
    overflow: hidden;
    span {
      display: block;
      height: 100%;
      width:  ${(props) => props.progress || 0}%;
      background-color: ${(props) => props.theme.success};
    }
  }
  .totalReplaced {
    text-align: right;
    font-size: 15px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};
    padding-top: 8px;
  }
`;

export const IconButton = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
  background-color: white;
  svg {
    color: ${(props) => props.theme.complementary};
    color: ${(props) => props.color === "alert" && props.theme.alert};
    color: ${(props) => props.color === "success" && props.theme.success};
    color: ${(props) => props.color === "error" && props.theme.error};
  }
`;

export const ProductCard = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: ${(props) => props.theme.radio};
    border: solid 1px ${(props) => props.theme.graySoft};
    position: sticky;
    top: 10px;
    &:has(.omitted) {
      height: 100%;
    }
    .omitted {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-height: 143px;
    }
    .image {
      flex: none;
      img {
        width: 140px;
        height: 140px;
        aspect-ratio: 1;
        object-fit: contain;
      }
    }
    .content {
      padding-left: 20px;
      .ref {
        font-size: 12px;
        font-weight: bold;
      }
      .title {
        margin-top: 5px;
        font-size: 18px;
      }
      .price {
        font-weight: normal;
        font-size: 14px;
        margin-top: 5px;
      }
      ${Button} {
        &.alert {
          color: ${(props) => props.theme.alert};
        }
        &.link {
          color: ${(props) => props.theme.complementary};
        }
        border-radius: ${(props) => props.theme.radio};
        svg {
          width: 20px !important;
          height: 20px !important;
          margin-left: 0 ;
        }
      }
    }

  .options {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: none;
  }

  .options-desktop {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    @media (max-width: ${breakpoint}) {
      display: none;
    }
  }
  .options-replace {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    display: block;
    @media (max-width: ${breakpoint}) {
      display: none;
    }
    &.omitted-replace {
      display: block;
    }
  }

  @media (max-width: ${breakpoint}) {
    .hide-on-breakpoint {
      display: none;
    }
    flex-direction: column;
    .image {
      img {
        width: 100px;
        height: 100px;
      }
    }
    .content {
      text-align: center;
      padding-left: 0;
      padding-top: 5px;
      .title {
        font-size: 10px;
        margin-top: 5px;
      }
    }
    .options {
      display: flex;
      flex-direction: column;
      ${IconButton} + ${IconButton} {
        margin-top: 10px;
      }
    }
  }
`;

export const ColumnLeft = styled.div`
  width: 50%;
  padding-right: 30px;
  position: relative;
  .indicator {
    position: absolute;
    top: 20px;
    right: 0;
    margin-right: -15px;
    width: 30px;
    height: 30px;
    z-index:2;
    border-radius: 100%;
    border: solid 1px transparent;
    cursor: pointer;
    svg {
      color: #e3e3e3;
      width: 30px !important;
      height: 28px !important;
    }
    &.success {
      svg {
        color: ${(props) => props.theme.success};
      }
    }
  }
  @media (max-width: ${breakpoint}) {
    padding-right: 5px;
    .indicator {
      top: 50%;
      transform: translateY(-50%);
      border-color: ${(props) => props.theme.graySoft};
      background-color: white;
    }
  }
`;

export const ColumnRight = styled.div`
  width: 50%;
  padding-left: 30px;

  @media (max-width: ${breakpoint}) {
    padding-left: 5px;
  }
`;

export const ProductRowList = styled.div`
  h4 {
    font-weight: 500;
  }
  ${(props) => {
    if (props.isMobile) {
      return `
        padding: 10px;
      `;
    }
    return "";
  }}
  @media (max-width: ${breakpoint}) {
    &.hide-on-breakpoint {
      display: none;
    }
  }
  .heading {
    max-width: 480px;
    text-align: center;
    margin: auto auto 10px;
    h2 {
      font-weight: normal;
      font-size: 22px;
    }
    h3 {
      font-size: 16px;
      margin-top: 10px;
    }
    p {
      margin-top: 10px;
    }
  }
  .description {
    text-align: center;
    margin-bottom: 35px;
    padding: 0 15px;
    margin-bottom: 100px;
  }
  .footer {
    position: fixed;
    width: 100%;
    background-color: white;
    display: none;
    bottom: 0;
    @media (max-width: 640px) {
      display: block;
    }
  }
  .feature-image {
    text-align: center;
    padding: 30px 0;
    img {
      display: block;
      width: 100%;
      height: 300px;
      object-fit: contain;
      @media (max-width: 768px) {
        height: 160px;
      }
    }
  }
  table {
    font-size: 14px;
    background-color: transparent;
    width: 100%;
    border: solid 1px ${(props) => props.theme.borderColor};
    color: ${(props) => props.theme.primaryDark};
    @media (max-width: 980px) {
        font-size: 12px;
    }
    th {
        width: 1px;
        white-space: nowrap;
    }
    td,
    th {
        padding: 5px;
    }
    tr + tr th,
    tr + tr td {
        border-top: solid 1px ${(props) => props.theme.borderColor};
    }
    .price_d {
        background-color: ${(props) => props.theme.grayLight};
    }
}
`;

export const ProductRow = styled.div`
    position: relative;
    display: block;
    & + & {
      margin-top: 20px;
    }
    .input {
      position: absolute;
      left: 10px;
      top: 0;
    }
    input[type="radio"] {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -10px;
      accent-color: ${(props) => props.theme.success};
    }
    .wrapper {
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 30px;
      border-radius: ${(props) => props.theme.radio};
      border: solid 1px ${(props) => (props.isSelected) 
        ? props.theme.success : props.theme.borderColor};
      box-shadow: 0px 11px 20px -18px rgba(0, 0, 0, 0.1);
      min-height: 80px;
      user-select: none;
      cursor: pointer;
      position: relative;
      ${IconButton} {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
      }
    }
  .image {
    flex: none;
      img {
        width: 50px;
        height: 50px;
        aspect-ratio: 1;
        object-fit: contain;
        &.select {
          margin-left: 7px;
        }
    }
  }
  .content {
    padding-left: 10px;
    width: 76%;
    position: relative;
    .title {
        font-size: 14px;
    }
    .info {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 78%;
        span {
            font-size: 12px;
            margin-top: 5px;
        }
    }
    .qty {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .control {
        display: flex;
        flex: none;
        align-items: center;
        .btn, input {
          height: 40px;
          border: solid 1px ${(props) => props.theme.borderColor};
        }
        .btn {
          width: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          font-size: 22px;
          font-weight: bold;
          flex: none;
          &:hover {
            background-color: ${(props) => props.theme.grayLight};
          }
          &:active {
            background-color: ${(props) => props.theme.graySoft};
          }
          svg {
            color: ${(props) => props.theme.complementary};
          }
          &:first-child {
            border-top-left-radius: ${(props) => props.theme.radio};
            border-bottom-left-radius: ${(props) => props.theme.radio};
          }
          &:last-child {
            border-top-right-radius: ${(props) => props.theme.radio};
            border-bottom-right-radius: ${(props) => props.theme.radio};
          }
        }
        input {
          width: 100px;
          border-left: none;
          border-right: none;
          padding: 5px;
          text-align: center;
        }
      }
      .total {
        padding-left: 10px;
        font-size: 12px;
      }
    }
  }
`;

export const FooterSection = styled.div`
  padding: 0 10px;
  margin-top: 50px;
  ${Container} {
    background-color: white;
    padding: 20px 0px;
    border-top: solid 1px ${(props) => props.theme.graySoft};
  }
  p.confirmed {
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    padding: 0;
    background-color: ${(props) => props.theme.white};
    ${Container} {
      padding: 10px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 3;
    }
  }
`;

export const ButtonExtended = styled(Button)`
  border: none;
  color: ${(props) => props.theme.gray};
  text-decoration: underline;
`;

export const ModalExtended = styled(Modal)`
  z-index: 999999999999999;
  .background {
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    background-color: black;
    opacity: 0.5;
  }
  & > div {
    max-height: 96%;
    padding: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: ${breakpoint}) {
      height: 100%;
      max-height: 100%;
    }
  }
  .btnCont {
    position: sticky;
    display: flex;
    top: 0;
    width: 100%;
    padding: 0px 10px 0;
    height: 35px;
    z-index: 9999;
    overflow: initial;
    margin-left: auto;
    justify-content: end;
    background-color: white;
    cursor: pointer;
  }
`;

export const ProductGroupRow = styled.div`
  display: flex;
  & + & {
    border-top: solid 1px ${(props) => props.theme.graySoft};
    margin-top: 50px;
    padding-top: 50px;
  }
  @media (max-width: ${breakpoint}) {
    & + & {
      border-top: none;
      padding-top: 0;
      margin-top: 20px;
    }
  }
`;

export const ProductTitleRow = styled.div`
  display: flex;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  color: ${(props) => props.theme.primary};
  @media (max-width: ${breakpoint}) {
    margin-bottom: 20px;
    font-size: 14px;
  }
`;

export const ProductPicker = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  border: solid 1px ${(props) => props.theme.graySoft};
  border-radius: ${(props) => props.theme.radio};
  height: 100%;
  cursor: pointer;
  user-select: none;

  @media (max-width: ${breakpoint}) {
    &.show-on-breakpoint {
      display: flex;
    }
  }
  div {
    text-align: center;
    width: 100px;
    svg {
        width: 30px !important;
        height: 30px !important;
        color: ${(props) => props.theme.complementary};
    }
    span {
        color: ${(props) => props.theme.complementary};
        font-size: 14px;
        display: block;
    }
  }
`;

export const ItemSeparator = styled.div`
  margin-top: 35px;
  h1 {
    color: ${(props) => props.theme.complementary};
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    text-transform: uppercase;
  }
  h1::before, h1::after {
    flex: 1;
    content: '';
    background-color: ${(props) => props.theme.graySoft};
    height: 1px;
    align-self: center;
  }
`;
