import React from 'react';
import { ProductPicker } from '../style';
import { Preloader } from "../../../sdk/Elements";
import Icons from './Icons';

const ProductPickerComponent = ({ lj, loading, className, onMouseUp }) => {
    return (
        <ProductPicker className={className} onMouseUp={onMouseUp}>
            <div>
                <Icons icon="find-replace"/>
                {!loading 
                    ? <span>{lj.selectBtn}</span>
                    : <Preloader /> }
            </div>
        </ProductPicker>
    );
}

export default ProductPickerComponent;
