/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import Blueberry from "../../../blueberry/index";
import { numberWithCommas, lookForObject } from "../../utils";
import {
	Header,
	Layout,
	Progress,
	NoDataCont,
	Spacer,
	FooterSection,
	ModalExtended,
	ColumnLeft,
	ColumnRight,
	ProductTitleRow,
	ItemSeparator,
} from "./style";
import { Container, Box } from "../../sdk/Layout";
import { Preloader, Notify } from "../../sdk/Elements";
import { Buttons, Button } from "../../sdk/Button";
import Icon from "./components/Icons";
import ProductGroup from "./ProductGroup";

function MainRecomProds({ lj, lang, public_key }) {
	const firstTime = React.useRef(true);
	const [prodsData, setProdsData] = React.useState({});
	const [items, setItems] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [confirmLoading, setConfirmLoading] = React.useState(false);
	const [modalMobile, setModalMobile] = React.useState(false);
	const [modalDetail, setModalDetail] = React.useState(false);
	const [confirmed, setConfirmed] = React.useState(false);
	const [notifyError, setNotifyError] = React.useState("");
	const langField = (lang === "en") ? "_eng" : (lang === "es") ? "" : `_${lang}`;
	const titleField = `title${langField}`;

	React.useEffect(() => {
		setNotifyError("");
		if ((prodsData?.canProds || []).length) {
			if (firstTime.current) {
				const itemsF = [...prodsData.canProds.filter((item) => !item.replaced)];
				itemsF.push(...(prodsData.canProds
					.filter((item) => item.replaced?.sku === "omitted")));
				itemsF.push(...(prodsData.canProds
					.filter((item) => (item.replaced && item.replaced?.sku !== "omitted"))));
				setItems(itemsF);
				firstTime.current = false;
			} else {
				setItems((prevItem) => prevItem
					.map((item) => lookForObject(prodsData?.canProds, "sku", item.sku)));
			}
		}
	}, [prodsData?.canProds]);

	const totalAmount = React.useMemo(() => (
		(prodsData || {}).canProds || [])
		.reduce((t, a) => t + a.price * a.quantity, 0), [prodsData?.canProds]);

	const totalReplaced = React.useMemo(() => (
		(prodsData || {}).canProds || []).reduce((t, a) => t + ((a.replaced || {}).price || 0)
            * ((a.replaced || {}).quantity || 0), 0), [prodsData?.canProds]);

	const percentage = React.useMemo(() => {
		if (totalAmount && totalReplaced) {
			return (totalReplaced / totalAmount) * 100;
		}
		return 0;
	}, [totalAmount, totalReplaced]);

	const totalPending = React.useMemo(() => {
		const resp = totalAmount - totalReplaced;
		if (resp < 0) return 0;
		return resp;
	}, [totalAmount, totalReplaced]);

	const getData = async (noLoading) => {
		try {
			if (!noLoading) setLoading(true);
			const resp = await Blueberry.getCanceledProdsOrder({
				lang,
				public_key,
			});
			setProdsData(resp);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const handleConfirm = async () => {
		if (!items.every((item) => item.replaced) ) {
			setNotifyError(lj.pendingreplacedProducts);
			return;
		}

		if (totalReplaced > 0) {
			setConfirmLoading(true);
			try {
				await Blueberry.setCanceledProdConfirm({ public_key });
				setConfirmed(true);
			} catch (err) {
				console.log(err);
			}
			setConfirmLoading(false);
		}
	};

	React.useEffect(() => {
		if (public_key) getData();
	}, [public_key]);

	const onCloseMobile = () => setModalMobile(false);
	const onCloseDetail = () => setModalDetail(false);

	const noDataFunc = () => {
		const text = (prodsData === "disabled") ? lj.disabledLink : lj.noData;
		return (
			<NoDataCont>
				<Spacer height="100" />
				<h3>{text}</h3>
			</NoDataCont>
		);
	};

	const itemSeparator = React.useMemo(() => items.findIndex((item) => item.replaced), [firstTime.current]);

	return (
		<>
			{(!prodsData || prodsData === "disabled") ? (
				noDataFunc()
			) : (
				<>
					<Layout>
						<Container>
							<Header>
								<div className="heading">
									<h1>{lj.title}</h1>
									{loading ? (
										<>
											<Spacer height="20" />
											<Preloader />
											<Spacer />
										</>
									) : (
										<p>
											{lj.description.replace("{{counter}}", prodsData.canProds.length)}
										</p>
									)}
								</div>
								<div className="orderDetailsCont">
									<Progress progress={percentage}>
										<div className="text">
											<h4>{`${lj.totalAmount} $${numberWithCommas(totalAmount)}`}</h4>
											<p>{`${lj.pending} $${numberWithCommas(totalPending)}`}</p>
										</div>
										<div className="progressbar">
											<span />
										</div>
										<div className="totalReplaced">
											{`${lj.totalReplaced} $${numberWithCommas(totalReplaced)}`}
										</div>
									</Progress>
									<Spacer height="6" />
									{!loading && (
										<div className="orderDetails">
											<p>
												{`${(prodsData?.order?.userName || lj.unknowClient)} 
                            (${prodsData?.order?.clientNumber})`}
											</p>
											<p>
												{lj.invoiceLabel}
												<strong>
													{prodsData?.order?.systemOrderNumber
                            || prodsData?.order?._id}
												</strong>
											</p>
											{
												prodsData?.order?.title && (
													<p>
														{lj.cart}
														<strong>
															{prodsData?.order?.title}
														</strong>
													</p>
												)
											}
											<p>
												{lj.salesrep}
												<strong>
													{prodsData?.order?.contact?.salesrep?.name}
												</strong>
											</p>
										</div>
									)}
								</div>
							</Header>
							{loading ? (
								<>
									<ProductGroup lj={lj} loading={loading} />
									<ProductGroup lj={lj} loading={loading} />
								</>
							) : (
								<>
									{
										items?.length > 0 && (
											<>
												{
													(itemSeparator !== 0) && (
														<ProductTitleRow>
															<ColumnLeft>{lj.canceledProd}</ColumnLeft>
															<ColumnRight>{lj.selectOption}</ColumnRight>
														</ProductTitleRow>
													)
												}
												{items.map((item, index) => {
													if (index === itemSeparator) {
														return (
															<React.Fragment key={item.productId}>
																<ItemSeparator>
																	<h1>{lj.replacedProducts}</h1>
																	<ProductTitleRow>
																		<ColumnLeft>{lj.replacedProduct}</ColumnLeft>
																		<ColumnRight>{lj.selectedProduct}</ColumnRight>
																	</ProductTitleRow>
																</ItemSeparator>
																<ProductGroup
																	//key={item.productId}
																	lj={lj}
																	data={{
																		...item,
																		titleField,
																		getData,
																		public_key,
																		setModalMobile,
																		setModalDetail,
																		allItems: items,
																		isMobile: (window.innerWidth <= 980),
																		order: prodsData.order,
																		products: prodsData.products,
																	}}
																	loading={loading}
																/>
															</React.Fragment>
														);
													}

													return (
														<ProductGroup
															key={item.productId}
															lj={lj}
															data={{
																...item,
																titleField,
																getData,
																public_key,
																setModalMobile,
																setModalDetail,
																allItems: items,
																isMobile: (window.innerWidth <= 980),
																order: prodsData.order,
																products: prodsData.products,
															}}
															loading={loading}
														/>
													);
												})}
											</>
										)
									}
								</>
							)}
						</Container>
						{
							notifyError && (
								<Notify
									top
									error
									onClick={() => setNotifyError("")}
								>
									{notifyError}
								</Notify>
							)
						}
					</Layout>
					{!loading && (
						<FooterSection>
							<Container className="container">
								<Buttons isRight>
									{
										confirmed ? (
											<p className="confirmed">{lj.confirmedBtn}</p>
										) : (
											<Button
												primary
												responsive
												loading={
													confirmLoading ? "true" : undefined
												}
												disabled={confirmed}
												white={confirmed}
												onMouseUp={handleConfirm}
											>
												{lj.confirmBtn}
											</Button>
										)
									}
								</Buttons>
							</Container>
						</FooterSection>
					)}
				</>
			)}
			{modalMobile && (
				<ModalExtended className="is-active">
					<div className="background" onMouseUp={onCloseMobile} />
					<Box>
						<div className="btnCont" onMouseUp={onCloseMobile}>
							<Icon icon="close" />
						</div>
						{modalMobile}
					</Box>
				</ModalExtended>
			)}
			{modalDetail && (
				<ModalExtended className="is-active">
					<div className="background" onMouseUp={onCloseDetail} />
					<Box>
						<div className="btnCont" onMouseUp={onCloseDetail}>
							<Icon icon="close" />
						</div>
						{modalDetail}
					</Box>
				</ModalExtended>
			)}
		</>
	);
}

export default MainRecomProds;
