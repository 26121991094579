import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import { Columns } from "../../sdk/Layout";
import { ItemsContainer } from "../ProductGrid2";
import { Item, ProductPrice } from "../Product2";
import { LookbookImagen, LookbookItems } from "./style";
import { Preloader } from "../../sdk/Elements";

function Product({ product, viewItem, loading }) {
	const handleViewItem = () => {
		viewItem(product.slug);
	};
	if (loading) {
		return (
			<Item>
				<a>
					<figure>
						<Preloader square />
					</figure>
				</a>
				<div>
					<p>
						{" "}
						<Preloader half />
					</p>
				</div>
			</Item>
		);
	}
	return (
		<Item>
			<a onClick={handleViewItem}>
				<figure>
					<LazyLoadImage
						src={
							product.imagesUrl && product.imagesUrl.length > 0
								? product.imagesUrl[0].thumb
								: ""
						}
					/>
				</figure>
			</a>
			<div>
				<p>
					<ProductPrice
						price={product.price}
						price_d={product.price_d}
						price_usa={product.price_usa}
						compare_at_price={product.compare_at_price}
					/>
				</p>
			</div>
		</Item>
	);
}

function ProductLook({ ambientProducts, viewItem, isLoadingProducts }) {
	if (isLoadingProducts) {
		return (
			<Columns>
				<LookbookImagen isNarrow>
					<figure>
						<Preloader square />
					</figure>
				</LookbookImagen>
				<LookbookItems>
					<ItemsContainer>
						{[
							1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
						].map((index) => (
							<Product
								key={`loading-shop-the-look${index}`}
								loading
							/>
						))}
					</ItemsContainer>
				</LookbookItems>
			</Columns>
		);
	}
	if (ambientProducts == null) return null;

	const { products, collection } = ambientProducts;

	let img =
		collection.imagesUrl && collection.imagesUrl.length > 0
			? collection.imagesUrl[0].url
			: "";

	if (img === "") {
		img =
			collection.images && collection.images.length > 0
				? collection.images[0]
				: "";
	}

	return React.useMemo(
		() => (
			<Columns>
				<LookbookImagen isNarrow>
					<figure>
						<LazyLoadImage src={img} alt={collection.title} />
					</figure>
				</LookbookImagen>
				<LookbookItems>
					<ItemsContainer>
						{products.map((index) => (
							<Product
								key={index._id}
								product={index}
								viewItem={viewItem}
							/>
						))}
					</ItemsContainer>
				</LookbookItems>
			</Columns>
		),
		[ambientProducts],
	);
}

ProductLook.propTypes = {
	viewItem: PropTypes.func.isRequired,
	ambientProducts: PropTypes.oneOf([PropTypes.object, null]),
};

export default React.memo(ProductLook);
