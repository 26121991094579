import React from "react";
import { Title } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { CommentsWrap, MessageExtended, TitleExtended } from "../style";
import Icons2 from "../../components/Icons2";
import { Control, Field } from "../../sdk/Form";
import { Message } from "../../sdk/Elements";
import useInput from "../../hooks/useInput";
import Blueberry from "../../../blueberry";
import useNotification from "../../hooks/useNotification";

function Comments({ _t, product }) {
	const [isLoading, setIsLoading] = React.useState(false);
	const [isDone, setIsDone] = React.useState(false);
	const [data, setData] = React.useState(null);

	const [didRequestMaximun, setRequestMaximun] = React.useState(false);

	const [handleNotify, NotifyComponent] = useNotification({
		timeout: 3000,
	});
	const [isQuestionVisible, setIsQuestionVisible] = React.useState(false);

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleAddQuestion();
		}
	};

	const [question, questionInput, setQuestion] = useInput({
		type: "text",
		placeholder: _t("pleaseAddQuestion"),
		defaultValue: "",
		onKeyPress: handleKeyPress,
		autoFocus: true,
	});

	const toggleModal = () => {
		setIsQuestionVisible((prevState) => !prevState);
	};

	const handleGetQuestions = () => {
		Blueberry.questions({
			productId: product._id,
			limit: 20,
			page: 1,
		})
			.then((resp) => {
				setData(resp);
			})
			.catch((err) => {
				handleNotify({
					paragraph: err.reason,
					error: true,
				});
			});
	};

	const handleAddQuestion = () => {
		if (question.trim() === "") {
			alert(_t("pleaseAddQuestion"));
			return;
		}

		setIsLoading(true);
		Blueberry.requestProductData({
			ask: "question",
			question,
			productId: product._id,
		})
			.then((resp) => {
				setIsLoading(false);
				setIsQuestionVisible(false);
				setQuestion("");
				handleNotify({
					paragraph: _t("informationRequested"),
					info: true,
				});
			})
			.catch((err) => {
				if (
					err &&
					err.reason === "reached maximun number of requests allowed"
				) {
					setRequestMaximun(true);
					setIsLoading(false);
					handleNotify({
						paragraph: _t("reachedMaximunRequest"),
						error: true,
					});
				} else {
					console.log(err);
					setIsDone(true);
					setIsLoading(false);
					handleNotify({
						paragraph: _t("informationRequested"),
						info: true,
					});
				}
			});
	};

	React.useEffect(() => {
		handleGetQuestions();
	}, [product._id]);

	return (
		<CommentsWrap>
			<TitleExtended as="h3" size="4">
				<Icons2 icon="comment" className="icon" />
				<span>{_t("questionTitle")}</span>
			</TitleExtended>
			<Button small onClick={toggleModal}>
				{_t("leaveAQuestion")}
			</Button>
			<MessageExtended>{_t("noQuestionsYet")}</MessageExtended>
			<NotifyComponent />
			{/* <p><a className="link">Inicie sesión</a> para dejar una pregunta.</p> */}
			{isQuestionVisible ? (
				<MessageExtended>
					<Field hasAddons>
						<Control isExpanded>{questionInput}</Control>
						<Control isExpanded>
							<Button primary onClick={handleAddQuestion}>
								{_t("sendAQuestion")}
							</Button>
						</Control>
					</Field>
				</MessageExtended>
			) : null}
			<ul className="items">
				{data
					? data.rows.map((index) => (
							<li className="item">
								<Icons2 icon="comment" className="q-icon" />
								<div className="question">
									<h5 className="title">{_t("question")}</h5>
									<p>{index.question}</p>
								</div>
								<div className="answer">
									<Icons2
										icon="arrow-right-bottom"
										className="icon"
									/>
									<p>
										<h5>{_t("response")}:</h5>{" "}
										{index.response}
									</p>
								</div>
							</li>
					  ))
					: null}
			</ul>
		</CommentsWrap>
	);
}

export default Comments;
