/* eslint-disable react/prop-types */
// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { ProductRowList } from "../style";
import { Buttons, Button } from "../../../sdk/Button";
import { DetailProductTable } from "../../../components/Product2";
import Gallery from "../../../components/Gallery2";

const ButtonsExt = styled(Buttons)`
	padding: 15px;
`;

const GalleryCont = styled.div`
	padding: 0 10px 10px;
`;

export default function ProductDetails({
	lj, item, titleField, close,
}) {
	const product = React.useMemo(() => (item?.products || []).filter(
		(prod) => prod.slug === item?.slug,
	)[0] || item, [item?.sku]);
	return (
		<ProductRowList>
			<div className="heading">
				<h2>{lj?.detailTitle}</h2>
				<h3>{product?.[titleField]}</h3>
				<h4>{`REF. ${product?.sku}`}</h4>
			</div>
			<GalleryCont>
				<Gallery
					images={product?.imagesUrl}
					videosUrl={product?.videosUrl}
					slug={
						`${product?.title
								|| product?.title_eng
								|| product?.title_pt
						} - ${
							product?.sku}`
					}
					noFullScreen
					zoomDefault="over"
				/>
			</GalleryCont>
			{product && (
				<div className="description">
					<DetailProductTable product={product} />
				</div>
			)}
			<div className="footer">
				<ButtonsExt isRight onMouseUp={() => close(false)}>
					<Button isFullWidth>{lj.exit}</Button>
				</ButtonsExt>
			</div>
		</ProductRowList>
	);
}
