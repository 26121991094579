import React from "react";
import { Section, Heading } from "../../sdk/Layout";
import ProductLook from "../../components/ProductLook";
import Blueberry from "../../../blueberry";
import { useInView } from "react-intersection-observer";

function CompleteTheLook({
    title,
    viewItem,
    ambientProducts,
    addToCart,
    slug,
    toggleModalProduct,
}) {
    const [isLoadingProducts, setIsLoadingProducts] = React.useState(false);
    const [products, setProducts] = React.useState(false);
    const refSlug = React.useRef();
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        // triggerOnce: true,
    });
    const getProducts = () => {
        setIsLoadingProducts(true);
        Blueberry.backWardFindCollectionProducts({ slug })
            .then((resp) => {
                setProducts(resp);
                setIsLoadingProducts(false);
            })
            .catch((err) => {
                setIsLoadingProducts(false);

                // this.setState({isLoadingItems: false, errorLoadingItems: true})
            });
    };

    React.useEffect(() => {
        if (inView && refSlug.current !== slug) {
            refSlug.current = slug;
            getProducts();
        }
    }, [inView]);

    if (
        !isLoadingProducts &&
        (products == null ||
            products.products == null ||
            products.products.length === 0)
    )
        return <div ref={ref} />;

    return (
        <Section>
            <Heading ref={ref}>
                <h2>{title}</h2>
            </Heading>
            <ProductLook
                ambientProducts={products}
                viewItem={viewItem}
                isLoadingProducts={isLoadingProducts}
            />
        </Section>
    );
}

export default React.memo(CompleteTheLook);
