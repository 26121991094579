/* eslint-disable camelcase */
class ProductVal {
	constructor({
		product, quantity, currentQuantity, validationType, priceType,
		isCart, isDirect, brandDiscount, discount, userBrands,
	}) {
		this.product = product;
		this.quantity = quantity;
		this.currentQuantity = currentQuantity || 0;
		this.isDirect = isDirect;
		this.priceType = priceType || (this.isDirect ? "d" : "panama");
		this.isCart = isCart;
		this.noRules = validationType === "noRules";
		this.validationType = validationType;
		this.userBrands = userBrands;
		this.brandDiscount = brandDiscount;
		this.discount = discount;
		this.multiple = product?.multiple || 1;
		this.available = product?.available || 0;
		if (validationType === "immediate") {
			this.available = (product?.warehouse || 0) - (product?.res_warehouse || 0);
		}
		this.getPrice = this.getPrice.bind(this);
		this.getMinQty = this.getMinQty.bind(this);
		this.getIsDirect = this.getIsDirect.bind(this);
	}

	getIsDirect() {
		return (this.priceType === "d");
	}

	getAvailable() {
		return this.available;
	}

	static calcDiscount(price, discount) {
		return Math.round(((price || 0) * (1 - (discount / 100))) * 100) / 100;
	}

	static rateDiscount(price, sourcePrice) {
		return Math.round10((((sourcePrice || price) - price) / sourcePrice) * 100, -1);
	}

	static getPriceField(type) {
		return (type && type !== "panama") ? `price_${type}` : "price";
	}

	getPrice() {
		const prices = {
			price: this.product.price,
			price_d: this.product.price_d,
			price_usa: this.product.price_usa,
			compare_at_price: this.product.compare_at_price,
		};
		const getDisc = (disc) => {
			Object.keys(prices).forEach((price) => {
				prices[price] = ProductVal.calcDiscount(prices[price], disc);
			});
		};
		if (this.brandDiscount) getDisc(this.brandDiscount);
		if (this.discount) getDisc(this.discount);
		prices.priceDisplay = prices[ProductVal.getPriceField(this.priceType)];
		return prices;
	}

	getMinQty() {
		return this.multiple;
	}

	getDefaultQty(justMinVenta) {
		return (this.getIsDirect() && !justMinVenta) ? this.multiple : this.product.min_venta;
	}

	getMaxQty() {
		if (this.getIsDirect() || !this.product?.check_availability || this.noRules) return null;
		return (this.isCart) ? this.available : (this.currentQuantity + this.available);
	}

	getNearby(params) {
		const quantity = params?.quantity || this.quantity;
		if (!this.noRules) {
			if (quantity === this.getMaxQty()) return quantity;
			if (this.getMaxQty() === 0) return 0;
		}
		const difMod = quantity % this.multiple;
		const max = Math.abs(difMod - this.multiple) + quantity;
		let value = difMod ? max : quantity;
		if (!params?.isUp) {
			const min = quantity - difMod;
			value = (max - quantity < Math.abs(min - quantity)) ? max : min;
		}
		if (value < this.getMinQty()) value = this.getMinQty();
		if (this.getMaxQty() && value > this.getMaxQty()) value = this.getMaxQty();
		return value;
	}

	hasAvailibility(quantityP) {
		if (this.noRules) return true;
		const quantity = quantityP || this.quantity;
		const checkAvailability = (this.getIsDirect()) ? false : this.product?.check_availability;
		if (checkAvailability && (this.available < 0 || parseInt(quantity, 10) > this.getMaxQty())) {
			return false;
		}
		return true;
	}

	validation(quantityP) {
		if (this.userBrands && this.product.brandId) {
			if (!this.userBrands.includes(this.product.brandId)) {
				return { error: "notBrand" };
			}
		}
		const quantity = quantityP || this.quantity;
		if (!this.noRules) {
			if (!this.hasAvailibility(quantity)) {
				return {
					error: "notAvailable",
					quantity: this.getMaxQty(),
					nearby: this.getNearby({ quantity }),
				};
			}
			if (quantity < this.getMinQty()) {
				return {
					error: "notMinimum",
					quantity: this.getMinQty(),
					nearby: this.getNearby({ quantity }),
				};
			}
		}
		if (quantity % this.multiple !== 0) {
			return {
				error: "notMultiple",
				quantity: this.multiple,
				nearby: this.getNearby({ quantity }),
			};
		}
		return {};
	}
}

export default ProductVal;
