import styled, { css } from "styled-components";
import { Icon, Button } from "../sdk/Button";
import { Message } from "../sdk/Elements";
import { Container, Title } from "../sdk/Layout";

export const NextPrevWrap = styled.div`
    display: flex;
    flex-grow: 1;
    margin-right: -0.5%;
    margin-left: -0.5%;
    min-height: 50px;
    @media (max-width: 768px) {
        display: none;
    }
`;
export const ContainerExtended = styled(Container)`
    margin-bottom: 20px;
`;
export const MessageExtended = styled(Message)`
    margin-top: 10px;
`;

const RightItemMixin = css`
    text-align: right;
    justify-content: flex-end;
    ${Icon} {
        right: inherit !important;
        left: 10px;
    }
`;

export const NextPrevItem = styled.a`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.primaryDark};
    border: 1px solid ${(props) => props.theme.borderColor};
    padding: 10px;
    border-radius: 3px;
    width: 49%;
    margin: 0.5%;
    cursor: pointer;
    position: relative;
    transition: all 200ms linear;
    &:hover {
        border-color: ${(props) => props.theme.primaryDarkMode};
        ${Icon} svg {
            color: ${(props) => props.theme.primaryDarkMode};
        }
    }
    p {
        font-size: 12px;
        color: ${(props) => props.theme.gray};
        padding: 0px 10px;
        padding-bottom: 5px;
        margin: 0;
    }
    h4 {
        padding: 0px 10px;
        font-size: 14px;
        margin: 0;
        @media (max-width: 480px) {
            font-size: 10px;
        }
    }
    figure {
        width: 50px;
        min-width: 50px;
        height: 50px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    ${(props) => props.right && RightItemMixin}

    ${Icon} {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -16px;
        svg {
            width: 20px;
            color: ${(props) => props.theme.gray};
            transition: all 200ms linear;
        }
    }
`;

export const CommentsWrap = styled.div`
    padding: 20px;
    border: solid 1px ${(props) => props.theme.borderColor};
    .items {
        padding-top: 40px;
    }
    .item {
        position: relative;
        padding-left: 30px;
        &:not(:last-child) {
            margin-bottom: 20px;
            border-bottom: solid 1px ${(props) => props.theme.borderColor};
            padding-bottom: 20px;
        }
        p {
            font-size: 14px;
            line-height: 22px;
        }
        .q-icon {
            position: absolute;
            left: 0;
            top: 0;
            color: ${(props) => props.theme.gray};
        }
        .question {
            padding-bottom: 10px;
            h5 {
                margin-bottom: 5px;
            }
        }
        .answer {
            padding-left: 20px;
            position: relative;
            h5 {
                display: inline-block;
            }
            .icon {
                position: absolute;
                top: 0;
                left: 0px;
                width: 13px !important;
                color: ${(props) => props.theme.gray};
            }
        }
    }

    @media (max-width: 480px) {
        ${Title} {
            font-size: 16px;
        }
    }
`;


export const TitleExtended = styled(Title)`
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
        flex: none;
    }
`;