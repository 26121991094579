import styled, { css } from "styled-components";
import { Container, Section, Title } from "../sdk/Layout";
import { Buttons, Button, Icon } from "../sdk/Button";
import { Field, Control, SelectBox } from "../sdk/Form";
import { Preloader } from "../sdk/Elements";

export const Wrapper = styled(Section)`
    padding-top: 0 !important;
`;

export const Left = styled.div`
    width: 35%;
    position: relative;
    .btn-play {
        /* position: absolute;
        top: 22px;

        left: 5px;

        z-index: 99; */
        display: none;
        margin: 10px auto;
        button {
            width: 100%;
        }
    }
    figure {
        position: relative;
        display: inline;
    }
    img {
        display: block;
        border-radius: 10px;
        margin: auto;
        position: sticky;
        max-width: 100%;
        top: 0;
        max-height: 100vh;
        &:hover {
            cursor: zoom-in;
        }
    }
    video {
        display: block;
        border-radius: 10px;
        margin: auto;
        position: sticky;
        max-width: 100%;
        width: 100%;
        top: 0;
        max-height: 100vh;
        &:hover {
            cursor: zoom-in;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        img {
            max-width: 100%;
        }
        video {
            max-width: 100%;
        }
        .btn-play {
            display: block;
        }
    }
`;

export const Right = styled.div`
    width: 65%;
    padding-left: 20px;
    @media (max-width: 768px) {
        padding-left: 0;
        padding-top: 10px;
        width: 100%;
    }
`;

export const Actions = styled.span`
    display: inline-block;
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    & ${Field} {
        margin-top: 10px;
    }

    @media (max-width: 1024px) {
        position: relative;
        bottom: initial;
        right: initial;
    }
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    border: solid 1px ${(props) => props.theme.borderColor};
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.primaryDark};
    background-color: ${(props) => props.theme.white};
    transition: box-shadow 200ms linear;
    position: relative;
    > div {
        width: 100%;
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    &:hover {
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
    }
    figure {
        width: 150px;
        height: 150px;
        display: inline-block;
        margin-right: 10px;
        flex: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        & ${Preloader} {
            width: 150px;
            height: 150px;
        }
    }

    ${Preloader} {
        width: 100%;
    }

    @media (max-width: 1024px) {
        display: block;
        text-align: center;
        figure {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
`;

export const Name = styled.h3`
    display: block;
    font-size: 16px;
    font-weight: normal;
`;

export const Price = styled.span`
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    display: block;
`;

export const Details = styled.ul`
    font-size: 0;
    padding-top: 10px;
    width: calc(100% - 240px);
    margin: auto;
    text-align: left;
    display: inline-block;
    span {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 5px;
        display: block;
        display: none;
    }
    li {
        font-size: 12px;
        line-height: 13px;
        position: relative;
        padding-left: 10px;
        &:not(:last-child) {
            margin-bottom: 7px;
        }
        &:before {
            content: "• ";
            color: #b7b9b9;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    @media (max-width: 1024px) {
        width: initial;
        padding: 15px 0 10px 0;
    }
`;

export const Navigation = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 12px;
    & ${Title} {
        margin-bottom: 0;
        text-transform: uppercase;
    }
    > div {
        margin: 0px 20px;
        position: relative;
        span {
            font-size: 14px;
            line-height: 22px;
            color: gray;
            display: block;
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
        }
    }

    @media (max-width: 540px) {
        & ${Title} {
            font-size: 1.4rem;
        }
        > div {
            margin: 0px 5px;
            span {
                font-size: 12px;
            }
        }
    }

    button:disabled,
    button[disabled] {
        opacity: 0.5;
    }
`;

export const expandedViewMixin = css`
    @media (min-width: 769px) {
        & ${Left}, & ${Right} {
            width: 50%;
        }
    }
    @media (max-width: 1320px) {
        & ${Item} {
            display: block;
            text-align: center;
            figure {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        & ${Actions} {
            position: relative;
            bottom: initial;
            right: initial;
        }
        & ${Details} {
            width: initial;
            padding: 15px 0 10px 0;
        }
    }
`;

export const fullwidthViewMixin = css`
    & ${Left} {
        width: 100%;
    }
    & ${Right} {
        padding-left: 0;
        padding-top: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5%;
        margin-right: -0.5%;
    }
    & ${Item} {
        display: block;
        text-align: center;
        width: 24%;
        margin: 0.5%;
        figure {
            margin-right: 0;
            margin-bottom: 10px;
        }
        @media (max-width: 1320px) {
            width: 32.333%;
        }
        @media (max-width: 980px) {
            width: 49%;
        }
        @media (max-width: 680px) {
            width: 99%;
        }
    }
    & ${Actions} {
        position: relative;
        bottom: initial;
        right: initial;
    }
    & ${Details} {
        width: initial;
        padding: 15px 0 10px 0;
    }
    & ${Buttons} {
        width: 100%;
        justify-content: center;
        padding-top: 30px;
    }
    .hide-on-fullwidth {
        display: none !important;
    }
`;

export const Cols = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ContainerExtended = styled(Container)`
    padding: 0 !important;
    ${(props) => props.expandedView && expandedViewMixin}
    ${(props) => props.fullwidthView && fullwidthViewMixin}
`;

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    & ${Button} {
        &.enlardged-button:focus {
            color: white !important;
        }
        svg {
            width: 18px;
            height: 18px;
            margin-right: -6px;
            margin-left: -6px;
        }
    }
`;
