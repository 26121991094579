export default {
	hero: {
		title: "Produtos para substituir <br /> Noritex, S.A.",
		description: "Você pode escolher a substituição de seus produtos cancelados.",
	},
	description: "Olá, ({{counter}}) produtos do seu pedido não serão fabricados, mas aqui mostramos algumas alternativa.",
	title: "Produtos para substituir",
	totalAmount: "Total a ser Substituído: ",
	pending: "Saldo a ser substituído:",
	confirmBtn: "Confirmar substituições",
	confirmedBtn: "Suas substituições foram enviadas ao vendedor. Você receberá um e-mail com o formulário atualizado.",
	detailsBtn: "Mais Detalhes",
	selectBtn: "Selecione uma substituição",
	noData: "Não há produtos cancelados para o seu pedido",
	cxbLabel: "Caixa de",
	seeMore: "Carregue mais",
	omittedBtn: "Pular esta substituição",
	totalLabel: "Total: ",
	selectOmmitted: "Substituição ignorada",
	accept: "Aceitar",
	selectTitle: "Seleção de substituto para",
	selectDescrip: "Por favor selecione uma opção.",
	selectDescrip1: "Você pode modificá-lo mais tarde, se desejar.",
	exit: "Fechar",
	detailTitle: "Detalhes do produto",
	modify: "Substituir",
	unknowClient: "Nenhum cliente",
	invoiceLabel: "Ordem: ",
	quantity: "Quantidade pedida",
	price: "Preço",
	salesrep: "Vendedor: ",
	noSelectBtn: "Descosindere",
	disabledLink: "Link expirou",
	canceledProd: "Produto cancelado",
	selectOption: "Opções para escolher",
	replacedProducts: "Produtos Substituídos",
	replacedProduct: "Produto Substituído",
	selectedProduct: "Produto selecionado",
	cart: "Carrinho: ",
	pendingreplacedProducts: "Há produtos que ainda precisam ser substituídos",
	totalReplaced: "Total Substituído:",
};
