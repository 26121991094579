/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from "react";
import Icons from "./Icons";
import ProductVal from "../../../../shared/imports/utils/ProductVal";
import Blueberry from "../../../../blueberry/index";
import { Preloader } from "../../../sdk/Elements";
import { numberWithCommas } from "../../../utils";
import { ProductRow } from "../style";
import IconButton from "./IconButton";

export default function ProductItem({
	item,
	lj,
	sku,
	selected,
	setSelected,
	loading,
	data,
	productDetail,
}) {
	const updateTimeout = React.useRef();
	const firstTime = React.useRef(true);
	const [quantity, setQuantity] = React.useState(
		item?.quantity || data?.quantity || 1,
	);
	const isSelected = item?.sku && selected?.sku === item?.sku;
	const noProd = item?.sku === "omitted" || item?.sku === "noSelect";
	const priceF = item?.price || item?.price_d || item?.price_usa || 0;

	const setReplace = async (quantityP) => {
		try {
			await Blueberry.setCanceledProdReplace({
				public_key: data.public_key,
				price: priceF,
				sku,
				newSku: item.sku,
				quantity: quantityP || quantity,
			});
			if (item.sku === "noSelect") {
				setSelected(null);
			} else {
				setSelected({ sku: item.sku, quantity: quantityP || quantity });
			}
			data.getData(true);
		} catch (err) {
			console.log(err);
		}
	};

	React.useEffect(() => {
		if (updateTimeout.current) clearTimeout(updateTimeout.current);
		if (!noProd && !loading && quantity !== "") {
			updateTimeout.current = setTimeout(() => {
				const gty = new ProductVal({
					product: item,
					quantity,
					isDirect: true,
				});
				const quantityF = gty.getNearby();
				setQuantity(quantityF);
				if (!firstTime.current && quantityF === quantity) setReplace(quantityF);
			}, 1500);
		}
	}, [quantity]);

	const getImage = () => {
		const noImage =			"https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg";
		return loading
			? noImage
			: (((item || {}).imagesUrl || [])[0] || {}).thumb || noImage;
	};

	const getContent = (value) => (loading ? <Preloader /> : value);

	const handleQuantity = (newValue) => {
		firstTime.current = false;
		if (newValue > 0) setQuantity(newValue);
	};

	return (
		<ProductRow isSelected={isSelected}>
			{!loading && (
				<input
					type="radio"
					name={sku}
					value={(item || {}).sku || ""}
					checked={isSelected}
					onChange={() => {}}
				/>
			)}
			<div className="wrapper" onMouseUp={() => setReplace()}>
				{noProd ? (
					<div className="content">
						<h3 className="title">{lj[`${item?.sku}Btn`]}</h3>
					</div>
				) : (
					<>
						<figure className="image">
							<img
								className="select"
								src={getImage()}
								alt={(item || {}).title}
							/>
						</figure>
						<div className="content">
							<h3 className="title">
								{getContent(item?.[data.titleField])}
							</h3>
							<div className="info">
								<span>{getContent((item || {}).sku)}</span>
								<span>
									{getContent(
										`${lj.price} $${numberWithCommas(
											priceF,
										)}`,
									)}
								</span>
								<span>
									{getContent(
										`${lj.cxbLabel} ${
											(item || {}).multiple || 1
										}`,
									)}
								</span>
							</div>
							{!loading && isSelected && (
								<div className="qty">
									<div className="control">
										<span
											className="btn"
											onMouseUp={() => handleQuantity(
												(quantity || 0)
														- (item.multiple || 1),
											)}
										>
											<Icons icon="less" />
										</span>
										<input
											type="text"
											value={quantity || ""}
											onChange={(e) => handleQuantity(
												e.target.value
													? parseInt(
														e.target.value,
														10,
													)
													: "",
											)}
										/>
										<span
											className="btn"
											onMouseUp={() => handleQuantity(
												(quantity || 0)
														+ (item.multiple || 1),
											)}
										>
											<Icons icon="more" />
										</span>
									</div>
									<div className="total">
										{`${lj.totalLabel} $${numberWithCommas(
											quantity * priceF,
										)}`}
									</div>
								</div>
							)}
						</div>
						{!loading && (
							<IconButton
								icon="info"
								color="alert"
								onMouseUp={(e) => {
									e.preventDefault();
									e.stopPropagation();
									productDetail(item);
								}}
							/>
						)}
					</>
				)}
			</div>
		</ProductRow>
	);
}
