import React from "react";
import { Helmet } from "react-helmet";
import Breadcrums from "../components/Breadcrums2";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import Blueberry from "../../blueberry/index";
import withTracker from "../../blueberry/connectPromise";
import ProductDetails2 from "../components/ProductDetails2";
import { NextPrevWrap, NextPrevItem, ContainerExtended } from "./style";
import { getParameterByName, getPredefinedOrder } from "../utils";
import { Section, Heading, Container } from "../sdk/Layout";
import { Icon, Button } from "../sdk/Button";
import Icons2 from "../components/Icons2";
import renderIf from "../utils/renderIf";
import ProductGrid from "../components/ProductGrid2";
import Listener from "../components/Listener";
import VideoPlayer from "../components/VideoPlayer2";
import SimilarProducts from "./components/SimilarProducts";
import CompleteTheLook from "./components/CompleteTheLook";
import Comments from "./components/Comments";
import { websiteName } from "../utils/SEOFieldsHelmet";

class Products extends ProductsSectionTemplate {
    constructor(props) {
        super(props);

        const queryResult = {};

        if (typeof window !== "undefined")
            queryResult.order = getPredefinedOrder(false);
        // const { collection, product } = this.props.match.params;

        if (props.location.search.indexOf("?") > -1) {
            const query = props.location.search.replace("?", "");
            const queryArray = query.split("&");
            for (var i = queryArray.length - 1; i >= 0; i--) {
                const qry = queryArray[i].split("=");
                if (qry[0] === "page") queryResult["page"] = parseInt(qry[1]);
                else if (qry[0] === "limit")
                    queryResult["limit"] = parseInt(qry[1]);
                else if (qry[0] === "order") queryResult["order"] = qry[1];
            }
        }

        this.state = {
            isAddingToCart: false,
            isProductModalVisible: false,
            product: {},
            isLoadingItems: false,
            errorLoadingItems: false,
            lastInCart: "",
            comments: "",
            nextSlug: "",
            backSlug: "",
            ...this.productDefaultState,
            ...queryResult,
        };
    }

    static async getInitialData(props) {
        const self = this;
        return new Promise((resolve, reject) => {
            const slug = props.match.params.product;
            const { brandSlug } = props.match.params;
            Blueberry.product({ slug, brandSlug })
                .then((resp) => {
                    if (typeof analytics !== "undefined")
                        analytics.track("Product Viewed", {
                            product_id: resp.slug,
                            ...resp,
                        });

                    resolve(resp);

                    // if (self.setState)
                    // 	self.setState({ comments: resp.content });
                })
                .catch((err) => {
                    reject(err);
                    console.log(err);
                });
        });
    }

    componentDidMount() {
        Blueberry.setLastKnownProduct(this.props.match.params.product);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            Blueberry.setLastKnownProduct(this.props.match.params.product);
        }
    }

    getTagsForQuery = () => {
        const { defaultTags, globalTags } = this.props.match.params;
        let tags = [];
        if (defaultTags) tags = tags.concat(defaultTags.split("+"));
        if (globalTags) tags = tags.concat(globalTags.split("+"));

        return tags;
    };

    retrieveProduct = (slug) => {
        this.nextProduct({});
        this.backProduct({});
        Blueberry.productView({ slug });
        this.setState({
            isLoadingItems: true,
            errorLoadingItems: false,
            isLoadingAmbient: true,
        });

        setTimeout(() => {
            Blueberry.productAssociations({ slug })
                .then((resp) => {
                    this.setState({
                        asociatedProducts: resp,
                    });
                })
                .catch(() => {
                    this.setState({
                        asociatedProducts: null,
                    });
                });
        }, 100);

        // Blueberry.itemToItemPurchasing({slug, limit: 5, logic: "ecommerce:similarly-purchasing"})
        // Blueberry.crossSell({ slug, limit: 5 });
    };

    nextProduct = (e, earlyRequest) => {
        // check if the next product was already requested
        if (
            this.props.match.params.collection === "" ||
            this.props.match.params.collection == null
        )
            return null;

        Blueberry.nextProduct({
            slug: this.props.match.params.product,
            order: this.state.order,
            collectionSlug: this.props.match.params.collection,
            brandSlug: this.props.match.params.brandSlug,
            defaultTags: this.getTagsForQuery(),
        })
            .then((resp) => {
                // if the request is on demand goto product
                if (resp) {
                    // if its an early request store the next slug and request the image in advance
                    this.setState({ nextSlug: resp.slug });
                    const img =
                        resp.imagesUrl && resp.imagesUrl.length > 0
                            ? resp.imagesUrl[0].url
                            : "";

                    let imageObj = new Image();
                    imageObj.src = img;
                } else {
                    this.setState({ nextSlug: null });
                }
            })
            .catch((err) => {
                this.setState({ nextSlug: null });
            });
    };

    backProduct = () => {
        if (
            this.props.match.params.collection === "" ||
            this.props.match.params.collection == null
        )
            return null;
        Blueberry.backProduct({
            slug: this.props.match.params.product,
            order: this.state.order,
            collectionSlug: this.props.match.params.collection,
            brandSlug: this.props.match.params.brandSlug,
            defaultTags: this.getTagsForQuery(),
        })
            .then((resp) => {
                if (resp) {
                    this.setState({ backSlug: resp.slug });
                } else {
                    this.setState({ backSlug: null });
                }
            })
            .catch((err) => {
                this.setState({ backSlug: null });
            });
    };

    timeoutNext = null;
    timeoutBack = null;

    handleNextProduct = (retries = 4) => {
        if (this.timeoutNext) {
            clearTimeout(this.timeoutNext);
            this.timeoutNext = null;
        }
        if (this.state.nextSlug == null || this.state.nextSlug === "") {
            if (retries > 0) {
                timeoutNext = setTimeout(() => {
                    this.handleNextProduct(retries - 1);
                }, 20);
            }
            return;
        }
        this.viewItem(this.state.nextSlug, null, true);
    };

    handleLastProduct = (retries = 4) => {
        if (this.timeoutNext) {
            clearTimeout(this.timeoutNext);
            this.timeoutNext = null;
        }
        if (this.state.backSlug == null || this.state.backSlug === "") {
            if (retries > 0) {
                timeoutNext = setTimeout(() => {
                    this.handleNextProduct(retries - 1);
                }, 20);
            }
            return;
        }

        this.viewItem(this.state.backSlug, null, true);
    };

    requestBackImage = () => {
        const { backSlug } = this.state;
        if (backSlug !== "") {
            const { product } = this.props.store;
            const backProduct = product[backSlug];
            const img =
                backProduct.imagesUrl && backProduct.imagesUrl.length > 0
                    ? backProduct.imagesUrl[0].url
                    : "";

            let imageObj = new Image();
            imageObj.src = img;
        }
    };

    renderBody() {
        const { product, crossSell, itemToItem, user } = this.props.store;
        const { jsonSettings } = this.props;
        const { backSlug, nextSlug, ambientProducts, asociatedProducts } =
            this.state;
        const { lang } = this.props.match.params;
        const slug = this.props.match.params.product;
        const breadcrums = [];
        const backProduct = product[backSlug];
        const nextProduct = product[nextSlug];

        const prd = product[slug];

        const { pathname } = this.props.location;

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const ptUrl = this.addLangDir("pt", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        if (prd == null)
            return <Listener user={user} action={this.props.refetch} />;

        if (this.props.match.params.globalTags) {
            breadcrums.push({
                to: `/collection/${this.props.match.params.collection}/${this.props.match.params.defaultTags}/${this.props.match.params.globalTags}/${this.props.location.search}`,
                title: this.props.match.params.collection,
            });
            breadcrums.push({ tags: this.props.match.params.defaultTags });
        } else if (
            this.props.match.params.defaultTags != null &&
            this.props.match.params.defaultTags !== ""
        ) {
            breadcrums.push({
                to: `/collection/${this.props.match.params.collection}/${this.props.match.params.defaultTags}/${this.props.location.search}`,
                title: this.props.match.params.collection,
            });
            breadcrums.push({ tags: this.props.match.params.defaultTags });
        } else if (this.props.match.params.collection != null) {
            breadcrums.push({
                to: `/collection/${this.props.match.params.collection}${this.props.location.search}`,
                title: this.props.match.params.collection,
            });
        } else if (
            this.props.location.search != null &&
            this.props.location.search.indexOf("qry") > -1
        ) {
            breadcrums.push({
                to: `/search/${this.props.location.search}`,
                title: `${getParameterByName(
                    "qry",
                    this.props.location.search
                )}`,
            });
        }

        return (
            <Section>
                <ContainerExtended className="hide-on-standalone">
                    <Breadcrums crums={breadcrums} />
                </ContainerExtended>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>{`${
                        prd.title || prd.title_eng || prd.title_pt
                    } - ${websiteName}`}</title>
                    <meta name="description" content={prd.content} />
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    <link rel="alternate" hreflang="pt" href={`${ptUrl}`} />
                    <script type="application/ld+json">
                        {`{
                            "@context": "https://schema.org/",
                            "@type": "Product",
                            "name": "${
                                prd.title || prd.title_eng || prd.title_pt
                            }",
                            "image": [
                                ${
                                    prd.imagesUrl &&
                                    prd.imagesUrl.map((index, i) =>
                                        i > 1 ? "," : "" + `"${index.url}"`
                                    )
                                }
                            ],
                            "description": "${prd.content}",
                            "sku": "${prd.sku}",
                            "brand": {
                                "@type": "Brand",
                                "name": "${prd.vendor}"
                            },
                            
                            "offers": {
                                "@type": "Offer",
                                "url": "https://www.noritex.com/${pathname}",
                                "priceCurrency": "USD",
                                "price": "00.00",
                                "priceValidUntil": "2030-11-20",
                                "availability": "https://schema.org/InStock",
                                "seller": {
                                "@type": "Organization",
                                "name": "${prd.vendor}"
                                }
                            }
                            }`}
                    </script>

                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta
                        property="og:title"
                        content={prd.title || prd.title_eng || prd.title_pt}
                    />
                    <meta
                        property="og:image"
                        content={
                            prd.imagesUrl && prd.imagesUrl.length > 0
                                ? prd.imagesUrl[0]
                                : ""
                        }
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={prd.content} />
                    {/*Twitter*/}
                    <meta
                        name="twitter:title"
                        content={prd.title || prd.title_eng || prd.title_pt}
                    />
                    <meta name="twitter:description" content={prd.content} />
                    <body className="product-detail" />
                </Helmet>
                <Container>
                    {" "}
                    <ProductDetails2
                        product={prd}
                        addToCart={this.addToCart}
                        removeItem={this.removeItem}
                        crossSell={crossSell}
                        nextSlug={nextSlug}
                        backSlug={backSlug}
                        refetch={this.props.refetch}
                        backProduct={backProduct}
                        nextProduct={nextProduct}
                        jsonSettings={jsonSettings}
                        asociatedProducts={asociatedProducts}
                        retrieveProduct={this.retrieveProduct}
                        handleNextProduct={this.handleNextProduct}
                        viewItem={this.viewItem}
                        handleLastProduct={this.handleLastProduct}
                    />
                    <NextPrevWrap className="hide-on-standalone">
                        {renderIf(backProduct != null)(
                            <NextPrevItem
                                right
                                onClick={this.handleLastProduct}
                                onMouseEnter={this.requestBackImage}
                                onTouchStart={this.requestBackImage}
                            >
                                <Icon>
                                    <Icons2 icon="chevron-double-left"></Icons2>
                                </Icon>
                                <span>
                                    <p>{this._t("back")}</p>
                                    <div>
                                        <h4>
                                            {backProduct
                                                ? backProduct.title ||
                                                  backProduct.title_eng ||
                                                  backProduct.title_pt
                                                : ""}
                                        </h4>
                                    </div>
                                </span>
                                <figure>
                                    <img
                                        src={
                                            backProduct &&
                                            backProduct.imagesUrl &&
                                            backProduct.imagesUrl.length > 0
                                                ? backProduct.imagesUrl[0].thumb
                                                : ""
                                        }
                                        alt={
                                            backProduct ? backProduct.slug : ""
                                        }
                                    />
                                </figure>
                            </NextPrevItem>
                        )}
                        {renderIf(nextProduct != null)(
                            <NextPrevItem onClick={this.handleNextProduct}>
                                <figure>
                                    <img
                                        src={
                                            nextProduct &&
                                            nextProduct.imagesUrl &&
                                            nextProduct.imagesUrl.length > 0
                                                ? nextProduct.imagesUrl[0].thumb
                                                : ""
                                        }
                                        alt={
                                            nextProduct ? nextProduct.slug : ""
                                        }
                                    />
                                </figure>
                                <span>
                                    <p>{this._t("next")}</p>
                                    <div>
                                        <h4>
                                            {nextProduct
                                                ? nextProduct.title ||
                                                  nextProduct.title_eng ||
                                                  nextProduct.title_pt
                                                : ""}
                                        </h4>
                                    </div>
                                </span>
                                <Icon>
                                    <Icons2 icon="chevron-double-right"></Icons2>
                                </Icon>
                            </NextPrevItem>
                        )}
                    </NextPrevWrap>
                    {/* <SimilarProducts
                        title={this._t("relatedProducts")}
                        slug={slug}
                        addToCart={this.addToCart}
                        scenario="crosssell"
                        limit={3}
                        toggleModalProduct={this.toggleModalProduct}
                    /> */}
                    <Comments _t={this._t} product={prd} />
                    <SimilarProducts
                        title={this._t("similarProducts")}
                        itemToItem={itemToItem}
                        viewItem={this.viewItem}
                        slug={slug}
                        addToCart={this.addToCart}
                        toggleModalProduct={this.toggleModalProduct}
                    />
                    <CompleteTheLook
                        title={this._t("comleteLook")}
                        ambientProducts={ambientProducts}
                        viewItem={this.viewItem}
                        slug={slug}
                        addToCart={this.addToCart}
                        toggleModalProduct={this.toggleModalProduct}
                    />
                </Container>
                <Listener user={user} action={this.props.refetch} />
            </Section>
        );
    }
}

export default withTracker()(Products);
