import React from "react";
import { Helmet } from "react-helmet";
import MainTemplate from "../../templates/MainTemplate";
import withTracker from "../../../blueberry/connectPromise";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import MainRecomProds from "./MainRecomProds";

function getLang(lang) {
  switch (lang) {
    case "es":
      return lang_es;
    case "en":
      return lang_en;
    case "pt":
      return lang_pt;
  }
}

class Page extends MainTemplate {

  renderHeader = () => null;
  renderFooter = () => null;

  renderBody() {
    const { lang, public_key } = this.props.match.params;
    const lj = getLang(lang);

    return (
      <>
        <SEOFieldsHelmet
          seo={null}
          lang={lang}
          defaultTitle={lj.hero.title.replace("<br />", "")}
          defaultDescription={lj.hero.description}
          bodyClassName="reset-body"
        />
        <Helmet>
          <meta name="googlebot" content="noindex" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <MainRecomProds lj={lj} lang={lang} public_key={public_key} />
      </>
    );
  }
}

export default withTracker()(Page);
