import React from 'react';
import Icons from "./Icons";
import { IconButton } from '../style';

const IconButtonComponent = ({icon="info", color, onMouseUp}) => {
    return (
        <IconButton color={color} onMouseUp={onMouseUp} >
            <Icons icon={icon} />
        </IconButton>
    );
}

export default IconButtonComponent;
