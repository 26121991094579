export default {
	hero: {
		title: "Productos para Reemplazar <br /> Noritex, S.A.",
		description: "Puedes escoger el reemplazo para tus productos cancelados.",
	},
	description: `Hola, ({{counter}}) productos de su orden no se van a fabricar, pero
        aquí le mostramos algunas alternativas.`,
	title: "Productos para reemplazar",
	totalAmount: "Total a reemplazar: ",
	pending: "Saldo por reemplazar:",
	confirmBtn: "Confirmar Reemplazos",
	confirmedBtn: "Sus reemplazos han sido enviados al vendedor. Recibirá un correo con la proforma actualizada.",
	detailsBtn: "Más detalle",
	selectBtn: "Seleccione un reemplazo",
	noData: "No existe productos cancelados para su pedido",
	cxbLabel: "Caja de",
	seeMore: "Cargar más",
	omittedBtn: "Omitir este reemplazo",
	totalLabel: "Total: ",
	selectOmmitted: "Reemplazo omitido",
	accept: "Aceptar",
	selectTitle: "Seleccionando reemplazo para",
	selectDescrip: "Por favor, seleccione una opción.",
	selectDescrip1: "Podrá modificarlo después si lo desea.",
	exit: "Cerrar",
	detailTitle: "Detalle del producto",
	modify: "Reemplazar",
	unknowClient: "Sin cliente",
	invoiceLabel: "Pedido: ",
	quantity: "Cantidad Pedida",
	price: "Precio",
	salesrep: "Vendedor: ",
	noSelectBtn: "Descartar selección",
	disabledLink: "El link ha expirado",
	canceledProd: "Producto cancelado",
	selectOption: "Opciones a escoger",
	replacedProducts: "Productos Reemplazados",
	replacedProduct: "Producto Reemplazado",
	selectedProduct: "Producto Seleccionado",
	cart: "Carrito: ",
	pendingreplacedProducts: "Hay productos pendientes por reemplazar",
	totalReplaced: "Total Reemplazado:",
};
