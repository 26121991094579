import React from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import withTracker from "../../blueberry/connectPromise";
import useDirectos from "../../blueberry/hooks/useDirectos";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import { ProductPrice } from "../components/Product2";
import { getProductMin } from "../components/Product2/AddToCart";
import Loader from "../components/Loader";
import Listener from "../components/Listener";
import Blueberry from "../../blueberry/index";
import FullScreen from "../components/Gallery2/FullScreen";
import TopBar from "./components/TopBar";
import { getOptions, getPredefinedOrder } from "../utils";

import { Container, Section, Title } from "../sdk/Layout";
import { Buttons, Button, Icon } from "../sdk/Button";
import { Field, Control, SelectBox } from "../sdk/Form";
import { Preloader } from "../sdk/Elements";
import Icons from "./components/Icons";
import IconsLibrary from "../components/Icons2";
import {
	Navigation,
	Wrapper,
	Cols,
	Right,
	Details,
	Name,
	Price,
	Actions,
	Left,
	Item,
	ButtonGallery,
	ContainerExtended,
	ContainerHeader,
} from "./style";
import renderIf from "../utils/renderIf";

const Product = (props) => {
	const [isAddingToCart, setIsAddingToCart] = React.useState(false);
	const isDirectos = useDirectos();
	const [quantity, setQuantity] = React.useState(
		type === "inventario" ? props.multiple : props.min_venta,
	);

	const { product } = props;

	// console.log(quantityInCart)
	React.useEffect(() => {
		if (props.quantityInCart > 0) setQuantity(props.quantityInCart);
	}, [props.quantityInCart]);

	const onClick = () => {
		props.toggleModalProduct(product);
	};

	const removeItem = () => {
		props.removeItem(product._id, product.slug);
	};

	const onChangeQuantity = (e) => {
		setQuantity(e.target.value);
	};

	const onAddToCart = () => {
		if (props.isInCart) {
			removeItem();
			return;
		}
		setIsAddingToCart(true);
		props.onAddToCart(product._id, parseInt(quantity), product).then(() => {
			setIsAddingToCart(false);
		});
	};

	const type =
		product.tags.length > 0
			? product.tags.indexOf("feria") > -1
				? "feria"
				: "inventario"
			: "inventario";

	let img =
		product.imagesUrl && product.imagesUrl.length > 0
			? product.imagesUrl[0].thumb
			: "";

	const options = getOptions(
		product.multiple,
		product.available,
		isDirectos ? false : product.check_availability,
		getProductMin(product, isDirectos),
	);

	return (
		<Item>
			<figure onClick={onClick} tabIndex="0" role="button">
				<LazyLoadImage src={img} alt={product.title} />
			</figure>
			<div>
				<Name>{product.title}</Name>
				<Price>
					<ProductPrice
						price={product.price}
						price_d={product.price_d}
						price_usa={product.price_usa}
						compare_at_price={product.compare_at_price}
					/>
				</Price>
				<Details>
					<span>Especificaciones</span>
					<li>
						{props.minQuantity}: {product.min_venta}
					</li>
					<li>
						{" "}
						{props.materialString}:{" "}
						{(product.detalle && product.detalle.material) ||
							(product.detalle_eng &&
								product.detalle_eng.material) ||
							(product.detalle_pt && product.detalle_pt.material)}
					</li>
					<li>
						{props.skuString}: {product.sku}
					</li>
				</Details>
			</div>
			<Actions>
				<Button isFullWidth onClick={onClick}>
					{props.quickView}
				</Button>

				<Field hasAddons>
					<Control>
						<SelectBox>
							<select
								disabled={props.isInCart}
								value={quantity}
								onChange={onChangeQuantity}>
								{options.map((index) => (
									<option
										value={index.value}
										key={`option-${product._id}-${index.value}`}>
										{index.value}
									</option>
								))}
							</select>
						</SelectBox>
					</Control>
					<Control>
						<Button
							onClick={onAddToCart}
							isFullWidth
							loading={isAddingToCart}>
							{props.isInCart
								? props.addToCartDone
								: props.addToCart}
						</Button>
					</Control>
				</Field>
			</Actions>
		</Item>
	);
};

class Ambient extends ProductsSectionTemplate {
	constructor(props) {
		super(props);
		let sizeSet = false;
		if (typeof window !== "undefined")
			sizeSet = localStorage.getItem("isEnlargedAmbient");

		this.state = {
			...this.productDefaultState,
			isGalleryVisible: false,
			page: 0,
			currentPage: 1,
			key: 1,
			addedToCart: [],
			limit: 24,
			selectedTags: [],
			sizeSet,
			order: getPredefinedOrder(false),
			isLoading: false,

			isAddingAllToCart: false,
			products: {
				products: [],
			},
		};
	}

	componentDidMount() {
		this.retrieveCollection();
		if (typeof window !== "undefined")
			document.addEventListener("keydown", this.keyboardListener, false);
	}

	componentDidUpdate(prevProps) {
		const { collection, index } = this.props.match.params;

		if (
			collection !== prevProps.match.params.collection ||
			index !== prevProps.match.params.index
		) {
			this.retrieveCollection();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyboardListener, false);
	}

	keyboardListener = (event) => {
		// left
		if (event.keyCode === 37) {
			this.onLastSlide();
		}

		// right
		if (event.keyCode === 39) {
			this.onNextSlide();
		}
	};

	toggleModalGallery = () => {
		this.setState((prev) => ({ isGalleryVisible: !prev.isGalleryVisible }));
	};

	refetchOnPriceChange = () => {
		this.retrieveCollection();
	};

	retrieveCollection = () => {
		const { collection, index } = this.props.match.params;
		const { children } = this.state;
		// const currentAmbient = this.props.children[index];
		this.setState({ isLoading: true });
		if (children && children.length > 0) {
			this.retrieveMenualProducts(children, index);
			return;
		}

		Blueberry.collectionChildren({ slug: collection, limit: 200 })
			.then((resp) => {
				this.setState({ children: resp });
				this.retrieveMenualProducts(resp, index);
			})
			.catch((err) => {
				this.setState({ isLoading: false });
				this.setState({ children: [] });
			});
	};

	retrieveMenualProducts = (children, index) => {
		const currentAmbient = children[parseInt(index)];

		if (currentAmbient == null) return;

		if (analytics)
			analytics.track("Product Ambient Viewed", {
				category: currentAmbient,
				slug: currentAmbient,
				ambientIndex: index,
			});

		Blueberry.productMaualCollections({
			slug: currentAmbient.slug,
			limit: 300,
		})
			.then((resp) => {
				this.setState({
					isPlayingVideo: false,
					products: resp,
					currentAmbient,
					isLoading: false,
				});

				if (
					resp &&
					resp.products &&
					this.state.product &&
					this.state.product.slug
				) {
					try {
						resp.products.map((prd) => {
							if (this.state.product.slug === prd.slug) {
								this.setState({ product: prd });
								throw Error("");
							}
						});
					} catch (err) {}
				}
			})
			.catch((err) => {
				this.setState({ isLoading: false });
				this.setState({ products: { products: [] } });
			});
	};

	handleEnlarged = () => {
		this.setState((prevState) => {
			localStorage.setItem("isEnlargedAmbient", "large");
			return {
				isPlayingVideo: false,
				sizeSet: "large",
				key: prevState.key + 1,
			};
		});
	};

	handleSmall = () => {
		this.setState((prevState) => {
			localStorage.setItem("isEnlargedAmbient", "mid");
			return {
				isPlayingVideo: false,
				sizeSet: "mid",
				key: prevState.key + 1,
			};
		});
	};

	handleFull = () => {
		this.setState((prevState) => {
			localStorage.setItem("isEnlargedAmbient", "full");
			return {
				isPlayingVideo: false,
				sizeSet: "full",
				key: prevState.key + 1,
			};
		});
	};

	addToCartCustom = (productId, quantity, product) => {
		return new Promise((resolve) => {
			Blueberry.productAssociations({ slug: product.slug })
				.then((resp) => {
					this.setState({
						asociatedProducts: resp,
					});
					setTimeout(() => {
						this.addToCart(productId, quantity, product)
							.then(() => {
								resolve();
							})
							.catch(() => {
								resolve();
							});
					}, 40);
				})
				.catch(() => {
					this.setState({
						asociatedProducts: null,
					});

					setTimeout(() => {
						this.addToCart(productId, quantity, product)
							.then(() => {
								resolve();
							})
							.catch(() => {
								resolve();
							});
					}, 40);
				});
		});
	};

	addAlToCart = () => {
		const { products } = this.state;
		if (products == null || products.products == null) return null;

		this.setState({ isAddingAllToCart: true });

		const productsToAdd = products.products.map((index) => ({
			productId: index._id,
			quantity: null,
		}));

		Blueberry.productAddToCartMany({ productsToAdd }).then(() => {
			this.setState({ isAddingAllToCart: false });
		});
	};

	removeItemCustom = (productId, slug) => {
		if (analytics)
			analytics.track("Product Removed", {
				product_id: slug,
			});

		return Blueberry.removeFromCart({ productId });
	};

	onClose = () => {
		const { lang, slug, collection } = this.props.match.params;
		this.props.history.replace(`/${lang}/ambient/${collection}/`);
	};

	onLastSlide = () => {
		const { collection, index, lang } = this.props.match.params;
		const newIndex = parseInt(index) - 1;
		if (newIndex < 0) return;
		this.props.history.replace(
			`/${lang}/ambient/${collection}/${newIndex}/`,
		);
		this.setState((prevState) => ({ key: prevState.key + 1 }));
	};

	onNextSlide = () => {
		const { collection, index, lang } = this.props.match.params;
		const newIndex = parseInt(index) + 1;
		if (newIndex >= this.state.children.length) return;
		this.props.history.replace(
			`/${lang}/ambient/${collection}/${newIndex}/`,
		);
		this.setState((prevState) => ({ key: prevState.key + 1 }));
	};

	handlePlayVideo = () => {
		this.setState((prev) => ({ isPlayingVideo: !prev.isPlayingVideo }));
	};

	renderHeader = () => {
		return null;
	};

	renderFooter = () => {
		return null;
	};

	renderLoader = () => {
		const { sizeSet } = this.state;
		return (
			<Wrapper>
				<Container isFluid>
					<Cols expandedView={sizeSet === "large"}>
						<Left>
							<figure>
								<Preloader square></Preloader>
							</figure>
						</Left>
						<Right>
							<Item>
								<figure>
									<Preloader square></Preloader>
								</figure>
								<div>
									<Preloader></Preloader>
									<Preloader half></Preloader>
								</div>
							</Item>
							<Item>
								<figure>
									<Preloader square></Preloader>
								</figure>
								<div>
									<Preloader></Preloader>
									<Preloader half></Preloader>
								</div>
							</Item>
							<Item>
								<figure>
									<Preloader square></Preloader>
								</figure>
								<div>
									<Preloader></Preloader>
									<Preloader half></Preloader>
								</div>
							</Item>
							<Item>
								<figure>
									<Preloader square></Preloader>
								</figure>
								<div>
									<Preloader></Preloader>
									<Preloader half></Preloader>
								</div>
							</Item>
							<Item>
								<figure>
									<Preloader square></Preloader>
								</figure>
								<div>
									<Preloader></Preloader>
									<Preloader half></Preloader>
								</div>
							</Item>
						</Right>
					</Cols>
				</Container>
			</Wrapper>
		);
	};

	renderActionButtons = (currentIndex, length) => {
		const { currentAmbient } = this.state;
		return (
			<Section>
				<Navigation>
					<Button
						small
						onClick={this.onLastSlide}
						disabled={currentIndex <= 0}>
						<Icons icon="chevron-double-left"></Icons>
						<span>{this._t("back")}</span>
					</Button>
					<div style={{ minWidth: 90 }}>
						<span>Ambiente</span>
						<Title size="5">
							{currentAmbient ? currentAmbient.title : ""}
						</Title>
					</div>
					<Button
						small
						onClick={this.onNextSlide}
						disabled={length - 1 <= currentIndex}>
						<span>{this._t("next")}</span>
						<Icons icon="chevron-double-right"></Icons>
					</Button>
				</Navigation>
			</Section>
		);
	};

	renderProducts = () => {
		const { currentAmbient, products, sizeSet, isLoading } = this.state;
		const { jsonSettings } = this.props;
		const { cartMap } = this.props.store;

		if (isLoading) {
			return null;
		}

		if (products && products.products.length <= 0) return null;
		return products.products.map((index, i) => {
			return (
				<Product
					isEnlarged={"large" === sizeSet}
					quickView={this._t("quickView")}
					isEditPossible={jsonSettings.isEditPossible}
					addToCart={this._t("addToCart")}
					minQuantity={this._t("minQuantity")}
					addToCartDone={this._t("removeFromCart")}
					materialString={this._t("material")}
					skuString={this._t("sku")}
					onAddToCart={this.addToCartCustom}
					removeItem={this.removeItemCustom}
					isInCart={cartMap.hasOwnProperty(index._id)}
					quantityInCart={cartMap[index._id]}
					toggleModalProduct={this.toggleModalProduct}
					key={index._id}
					product={index}
				/>
			);
		});
	};

	renderBody() {
		if (this.props.store.user.id == null) {
			return <Loader />;
		}

		const { lang, index } = this.props.match.params;
		const { pathname } = this.props.location;
		const { cart, stats, user } = this.props.store;
		const {
			currentAmbient,

			isLoading,
			isAddingAllToCart,
			isGalleryVisible,
			sizeSet,
			isPlayingVideo,
		} = this.state;

		const baseUrl = this.stripLangDir(lang, pathname);
		const enUrl = this.addLangDir("en", pathname, baseUrl);
		const esUrl = this.addLangDir("es", pathname, baseUrl);
		const title = this._t("Guided") + " - Noritex";
		const description =
			"Noritex guided Experience " + lang === "es"
				? "es una tienda en linea para comprar mayorista de decoracion, hogar y navidad. Nuestras marcas principales de decoracion son Santini y Concepts Life. En nuestra tienda podras encontrar variedad de muebles, figuras, arboles, y mas."
				: "it is an online store to buy decoration, home and Christmas in wholesale. Our main decoration brands are Santini and Concepts Life. In our store you can find a variety of furniture, figures, trees, and more.";

		const currentIndex = parseInt(index);

		let img = "";
		//if (currentAmbient == null)
		//    return <Listener user={user} action={this.retrieveCollection} />;
		if (currentAmbient != null) {
			img =
				currentAmbient.imagesUrl && currentAmbient.imagesUrl.length > 0
					? currentAmbient.imagesUrl[0].url
					: "";

			if (img === "") {
				img =
					currentAmbient.images && currentAmbient.images.length > 0
						? currentAmbient.images[0]
						: "";
			}
		}

		return (
			<>
				<Helmet>
					<title>{currentAmbient ? currentAmbient.title : ""}</title>
					<meta name="description" content={description} />
					<body className="reset-body" />
					<meta
						name="keywords"
						content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
					/>
					{/*Alternate*/}
					<link rel="alternate" hreflang="es" href={`${esUrl}`} />
					<link rel="alternate" hreflang="en" href={`${enUrl}`} />
					{/*Facebook*/}
					<meta property="og:site_name" content="Noritex" />
					<meta
						property="og:url"
						content={`https://www.noritex.com${pathname}`}
					/>
					<meta property="og:title" content={title} />
					<meta property="og:type" content="website" />
					<meta property="og:description" content={description} />
					{/*Twitter*/}
					<meta name="twitter:title" content={title} />
					<meta name="twitter:description" content={description} />
				</Helmet>
				<FullScreen
					images={currentAmbient ? currentAmbient.imagesUrl : ""}
					toggleModal={this.toggleModalGallery}
					modal={isGalleryVisible}
					currentImage={img}
					set={() => {}}
					alt={""}
				/>
				<TopBar
					onClose={this.onClose}
					cart={cart}
					stats={stats}
					removeItem={this.removeItemCustom}
				/>
				{this.renderActionButtons(
					currentIndex,
					this.state.children ? this.state.children.length : 0,
				)}
				{isLoading ? (
					this.renderLoader()
				) : (
					<Wrapper>
						<ContainerExtended
							isFluid
							expandedView={sizeSet === "large"}
							fullwidthView={sizeSet === "full"}>
							<ContainerHeader className="hide-on-mobile">
								<div>
									<Field hasAddons>
										<Control>
											<Button
												primary={sizeSet === "mid"}
												className="enlardged-button"
												onClick={this.handleSmall}>
												<Icons
													icon={"compress"}></Icons>
											</Button>
										</Control>
										<Control>
											<Button
												primary={sizeSet === "large"}
												className="enlardged-button"
												onClick={this.handleEnlarged}>
												<Icons icon={"expand"}></Icons>
											</Button>
										</Control>
										<Control>
											<Button
												primary={sizeSet === "full"}
												className="enlardged-button"
												onClick={this.handleFull}>
												<Icons icon="grip-lines"></Icons>
											</Button>
										</Control>
										<Control>
											<Button
												onClick={
													this.toggleModalGallery
												}>
												<Icons icon="search-plus"></Icons>
											</Button>
										</Control>
										{renderIf(
											currentAmbient &&
												currentAmbient.videosUrl &&
												currentAmbient.videosUrl
													.length > 0,
										)(
											<Control>
												<Button
													onClick={
														this.handlePlayVideo
													}>
													<Icons
														icon={
															isPlayingVideo
																? "close-light"
																: "play"
														}></Icons>
												</Button>
											</Control>,
										)}
									</Field>
								</div>
								<div className="hide-on-fullwidth">
									<Button
										primary
										loading={isAddingAllToCart}
										onClick={this.addAlToCart}>
										<IconsLibrary icon="shopping-cart"></IconsLibrary>
										<span>Agregar todos al carrito</span>
									</Button>
								</div>
							</ContainerHeader>
							<Cols>
								<Left>
									{renderIf(
										currentAmbient &&
											currentAmbient.videosUrl &&
											currentAmbient.videosUrl.length > 0,
									)(
										<div className="btn-play show-on-mobile">
											<Button
												white
												onClick={this.handlePlayVideo}>
												<Icons
													icon={
														isPlayingVideo
															? "close-light"
															: "play"
													}></Icons>
											</Button>
										</div>,
									)}
									{renderIf(!isPlayingVideo)(
										<figure>
											<img
												src={img}
												decoding="async"
												onClick={
													this.toggleModalGallery
												}
											/>
										</figure>,
									)}
									{renderIf(
										isPlayingVideo &&
											currentAmbient &&
											currentAmbient.videosUrl &&
											currentAmbient.videosUrl.length > 0,
									)(
										<video width={400} controls autoPlay>
											<source
												src={
													currentAmbient &&
													currentAmbient.videosUrl &&
													currentAmbient.videosUrl
														.length > 0
														? currentAmbient
																.videosUrl[0]
																.url
														: ""
												}
												type="video/mp4"
												autoPlay
											/>
											Your browser does not support HTML
											video.
										</video>,
									)}
								</Left>
								<Right>
									{this.renderProducts()}

									<Buttons isRight responsive>
										<Button
											primary
											loading={isAddingAllToCart}
											onClick={this.addAlToCart}>
											<IconsLibrary icon="shopping-cart"></IconsLibrary>
											<span>
												Agregar todos al carrito
											</span>
										</Button>
									</Buttons>
								</Right>
							</Cols>
						</ContainerExtended>
						{
							currentAmbient == null && (
								<Listener user={user} action={this.retrieveCollection} />
							)
						}
					</Wrapper>
				)}
				{this.renderActionButtons(
					currentIndex,
					this.state.children ? this.state.children.length : 0,
				)}
			</>
		);
	}
}

export default withTracker()(Ambient);

// export default MyAccount
